import { BLACK_SHADE, TEXT_SIZE_NORMAL } from "../../utils/constants";
import { ASSET_IMG_BLUE_SLAB } from "../../utils/constants/assetkeys";
import { isPCDevice } from "../_utils/deviceType";
import { parseStyle } from "../_utils/styleUtilities/_position";
import { createImage, createText } from "../_utils/styleUtilities/_scaling";

export const renderBottomSlab = (scene) => {
  let yOffset = isPCDevice() ? parseStyle("yp-10") : parseStyle("yp-20")
  const xPos = parseStyle("w-50")
  const yPos = parseStyle("h-100") - yOffset

  createImage(scene, xPos, yPos, ASSET_IMG_BLUE_SLAB)
    .setOrigin(0.5, 0.5)

  createText(scene, xPos, yPos, scene.goal, {
    fontSize: TEXT_SIZE_NORMAL,
    fill: BLACK_SHADE,
    fontFamily: "Arial",
  }).setOrigin(0.5, 0.5);
};
