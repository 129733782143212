import React from "react";

const ProgressBar = ({ progress = 0 }) => {
  return (
    <div>
      <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-300">
        <div class="bg-orange-shade h-1.5 rounded-full" style={{width: `${progress}%`}}></div>
      </div>
    </div>
  );
};

export default ProgressBar;