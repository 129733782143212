import { autorun } from "mobx";
import { ASSET_IMG_ICON_EXIT } from "../../../utils/constants/assetkeys";
import { setCursorToDefault, setCursorToPointer } from "../../GridProblems/_utils/cursorUtils";
import { createImage } from "../../_utils/styleUtilities/_scaling";
import { userDataStore } from "../../../stores/userDataStore";

export class ExitButton {
  constructor(scene, xPos, yPos, action) {
    this.scene = scene
    this.xPos = xPos
    this.yPos = yPos
    this.action = action

    this.createExitButton()
  }

  createExitButton() {
    const { scene, xPos, yPos } = this

    const xPosition = xPos;
    const yPosition = yPos;

    const exitButton = 
      createImage(scene, xPosition, yPosition, ASSET_IMG_ICON_EXIT)
      .setInteractive();

    exitButton.on("pointerover", () => {
      exitButton.setAlpha(0.95);
      setCursorToPointer(scene);
    });

    exitButton.on("pointerout", () => {
      exitButton.setAlpha(1);
      setCursorToDefault(scene);
    });

    exitButton.on("pointerdown", () => {
      if(this.action) {
        this.action()
      }
      autorun(() => {
        window.location.href = `/course/${userDataStore.currentCourse.id}`
      })
    });

    return exitButton
  }
}
