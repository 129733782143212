import { boardStore } from "../../_stores/BoardStore";

export const parseStyle = (key) => {
  const [type, percentage] = key.split('-');
  
  let value = 0;
  let percent = parseFloat(percentage)

  if (type === 'xp' || type === 'w') {
    value = (percent / 100) * boardStore?.gameWidth;
  } else if (type === 'yp' || type === 'h') {
    value = (percent / 100) * boardStore?.gameHeight;
  } else {
    value = 0;
  }

  return value;
};