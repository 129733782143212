import React from 'react'
import LinkButton from "../../components/CustomUI/LinkButton"
import HeroImage from "./assets/hero.jpg"
import Container from "./components/Container"
import { useNavigate } from "react-router-dom"

const HeroSection = () => {
  const navigate = useNavigate();

  const handleGoToCourses = () => {
    navigate("/courses")
  }

  return (
    <div className="bg-white px-5 md:px-5">
      <Container>
        <div className="grid md:grid-cols-2 h-[100%] rever min-h-[90vh] md:min-h-[70vh] gap-10">
          <div className="flex flex-col order-2 md:order-1 justify-center md:text-left text-center h-[100%]">
            <div className="">
              <h2 className="lg:text-[48px] md:text-[42px] text-[36px] font-semibold">Every Leader was once a Follower</h2>
              <p className="text-lg text-gray-500">Introducing SCALE - From Beginner to a Master and the Space in between</p>

              <div className="flex md:justify-start justify-center">
                <div onClick={handleGoToCourses} className="mt-5 w-[150px] h-[50px] capitalize">
                  <LinkButton color="blue" textSize="18px">
                    <p>Get Started</p>
                  </LinkButton>
                </div>
              </div>

            </div>
          </div>

          <div className="flex justify-center order-1 md:order-2 items-center">
            <img alt="syncing mind and brain illustration" src={HeroImage} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default HeroSection