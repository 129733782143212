import React, { useEffect, useState } from 'react';

const EnvironmentBadge = () => {
  const [visible, setVisible] = useState(false);
  const [environment, setEnvironment] = useState('');
  const [color, setColor] = useState("#0d0d0d")

  useEffect(() => {
    const env = process.env.NODE_ENV;
    if (env === "development" || env === "staging") {
      setEnvironment(env);
      setVisible(true);
    }

    if(env === "staging") setColor("#008e00");
  }, []);

  return (
    <div>
      {visible && (
        <div
          onClick={() => setVisible(false)}
          className="fixed top-10 right-[-40px] transform rotate-45 w-[200px] h-[30px] hover:opacity-75 cursor-pointer text-center py-1 bg-[${color}] z-50"
          style={{ transformOrigin: 'center center', background: color }}
        >
          <p className="border-white border-t-[1px] border-b-[1px] text-white font-semibold border-dotted text-sm">
            {environment}
          </p>
        </div>
      )}
    </div>
  );
};

export default EnvironmentBadge;
