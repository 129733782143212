import React from "react";

const Container = ({ children }) => {
  return (
    <div className="flex justify-center">
      <div className="w-[100%] max-w-[1100px] px-3 lg:px-0">{children}</div>
    </div>
  );
};

export default Container;
