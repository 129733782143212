import Phaser from "phaser";
import {
  SCENE_GAME,
} from "../../../../../utils/constants";
import { Background } from "../../../../GridProblems/_components/Background";

import { renderPrimaryNumbers } from "./_renderPrimaryNumbers";
import { renderDraggableNumbers } from "./_renderDraggableNumbers";
import { settingStore } from "../../../../_stores/SettingsStore";
import { gameOverCallback } from "./_gameOver";
import { renderBaseScene } from "../../../../_components/_renderBaseScene";
import { getActivityDetails } from "../../../../../services/activities/service";
import { userDataStore } from "../../../../../stores/userDataStore";

export default class Game extends Phaser.Scene {
  constructor() {
    super(SCENE_GAME);

    this.score = undefined;
    this.moves = undefined;

    this.level = undefined;
    this.isAscending = undefined;

    this.timeLeft = null;
    this.timer = null;
    this.timerText = null;

    this.numberObjects = undefined;
    this.isAscending = true;
    this.goal = "Drag the numbers to arrange in sequence."
    this.pad = 10;
  }

  init() {
    this.tileGap = 30;
    this.score = 0;
    this.moves = [];
    
    this.numberObjects = [];

    settingStore.infoModalOpen = false;
    settingStore.settingsOpen = false;
  }

  preload() {
    new Background(this);
  }

  loadGameConfig() {
    getActivityDetails(userDataStore.currentExercise.id, userDataStore.currentActivityId).then((res) => {
      this.level = res;

      userDataStore.setCurrentActivity(this.level)

      this.level.configurations.sort_order === "desc" ?
        this.isAscending = false : 
        this.isAscending = true;

      this.timeLeft = this.level.time_required

      this.renderGame();
    })
  }

  renderGame() {
    renderPrimaryNumbers(this, this.isAscending);
    renderDraggableNumbers(this, this.isAscending);

    const gameOverCondition = () => {
      return this.timeLeft <= 0 || this.numberObjects.length === this.level.configurations.numbers.length + this.level.configurations.draggables.length
    }

    renderBaseScene(this, gameOverCondition, gameOverCallback, {
      isExitButton: true, 
      isMuteButton: true,
      isInfoButton: true, 
      isSettingsButton: true
    });
  }

  create() {
    this.loadGameConfig()
  }
}
