import { computed, makeAutoObservable, observable } from "mobx";

class BoardStore {
  rows
  columns
  gameWidth
  gameHeight
  tileGrid
  gameID

  constructor(){
    makeAutoObservable(this, {
      rows: observable,
      columns: observable,
      gameWidth: observable,
      gameHeight: observable,
      tileGrid: observable,
      tileWidth: observable,
      tileHeight: observable,
      gameID: observable,
      selectBuffer: computed,
      boardWidth: computed,
      boardHeight: computed,
      leftBuffer: computed,
      topBuffer: computed,
      scaleFactor: computed
    });
  }

  get selectBuffer() {
    return this.calculateAvailableSpace() / 8;
  }

  get boardWidth() {
    return this.rows * this.tileWidth;
  }

  get boardHeight() {
    return this.columns * this.tileHeight;
  }

  get leftBuffer() {
    return (this.gameWidth - this.boardWidth) / 2;
  }

  get topBuffer() {
    return (this.gameHeight - this.boardHeight) / 2;
  }

  get scaleFactor() {
    return this.initializeScaleFactor();
  }

  initializeScaleFactor() {
    const devicePixelRatio = window.devicePixelRatio;
    if(devicePixelRatio > 2) {
      return 1;
    } else {
      return devicePixelRatio / 2;
    }
  }

  calculateAvailableSpace() {
    return Math.floor((
      Math.min(this.gameWidth * 0.95, this.gameHeight * 0.9) * 0.9
      ) / this.rows
    );
  }
}

export const boardStore = new BoardStore();