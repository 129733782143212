import { isMobileDevice, isTabletDevice } from "./deviceType"
import { parseStyle } from "./styleUtilities/_position"

export const getControlButtonPositions = () => {
  const iconSize = parseStyle("w-5")

  let settingsButtonX = iconSize
  let settingsButtonY = iconSize

  if(isMobileDevice() || isTabletDevice()) {
    settingsButtonX += parseStyle("w-10")
    settingsButtonY += parseStyle("h-5")
  }

  const mobileOffsetX = parseStyle("w-15")
  const mobileOffsetY = parseStyle("h-5")

  const infoButtonX = settingsButtonX
  const infoButtonY = (settingsButtonY + iconSize)

  return {
    iconSize, 
    settingsButtonX, 
    settingsButtonY, 
    mobileOffsetX, 
    mobileOffsetY, 
    infoButtonX, 
    infoButtonY
  }
}