import { action, makeAutoObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

class UserDataStore {
  currentCourse = undefined
  currentExercise = undefined
  currentActivity = undefined
  currentActivityId = undefined
  exerciseAttemptId = undefined
  activityAttemptId = undefined
  activities = []
  activitiesState = []

  constructor() {
    makeAutoObservable(this, {
      currentCourse: observable,
      currentExercise: observable,
      currentActivity: observable,
      exerciseAttemptId: observable,
      activityAttemptId: observable,
      activities: observable,
      currentActivityId: observable,
      setCurrentCourse: action,
      setCurrentExercise: action,
      setCurrentActivity: action,
      setActivities: action,
      setActivityAttemptId: action,
      setExerciseAttemptId: action,
    });

    makePersistable(this, {
      name: 'UserDataStore',
      properties: ['currentCourse', 'currentExercise', 'currentActivity', 'exerciseAttemptId', 'activityAttemptId', 'activities'],
      storage: window.localStorage
    })
  }

  setCurrentCourse(course) {
    this.currentCourse = course
  }

  setCurrentExercise(exercise) {
    this.currentExercise = exercise
  }

  setCurrentActivity(activity) {
    this.currentActivity = activity
  }

  setCurrentActivityId(id) {
    this.currentActivityId = id
  }

  setActivities(activities) {
    this.activities = activities
  }

  setActivityAttemptId(id) {
    this.activityAttemptId = id
  }

  setExerciseAttemptId(id) {
    this.exerciseAttemptId = id
  }
}

export const userDataStore = new UserDataStore()