import Phaser from "phaser";
import { createLoadingScreen } from "../../../_utils/gameLoader";
import { loadCommonAssets } from "../../../_utils/assets";
import { SCENE_BOOTSTRAP, SCENE_MAIN_MENU } from "../../../../utils/constants";
import {
  PATH_COMMON_ASSET,
  PATH_LOGO_WORD_SEARCH,
} from "../../../_utils/constants";
import { ASSET_IMG_LOGO } from "../../../../utils/constants/assetkeys";
import { boardStore } from "../../../_stores/BoardStore";
import { GAME_ID_WORD_SEARCH } from "@rewire-brain/grid-engine/utils/constants";

export default class Bootstrap extends Phaser.Scene {
  constructor() {
    super({ key: SCENE_BOOTSTRAP });
  }

  preload() {
    createLoadingScreen(this);
    this.loadGameAssets();
  }

  create() {
    this.createNewGame();
    boardStore.gameID = GAME_ID_WORD_SEARCH
  }

  loadGameAssets() {
    this.load.image(ASSET_IMG_LOGO, PATH_LOGO_WORD_SEARCH);
    loadCommonAssets(this, PATH_COMMON_ASSET);
  }

  createNewGame() {
    this.scene.start(SCENE_MAIN_MENU);
  }
}
