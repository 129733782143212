import Phaser from "phaser";

import {
  GridEngine,
  ScoreManager,
  LabelManager,
  TimerManager,
  ControlsManager,
  EventsManager,
} from "@rewire-brain/grid-engine";
import { settingStore } from "../../../_stores/SettingsStore";
import { autorun } from "mobx";
import { EVENT_THEME_CHANGE, LIGHT_THEME, SCENE_RESULT } from "../../../../utils/constants";
import {
  EVENT_GAME_OVER,
  EVENT_GAME_RUNNING,
  GAME_ID_WORD_SEARCH,
  GAME_TYPE_WORD,
} from "@rewire-brain/grid-engine/utils/constants";
import { Background } from "../../_components/Background";
import { calculateResults } from "../../_utils/resultsHelper";
import { renderControlButtons } from "../../../_components/_renderBaseScene";
import { themeChangeEventObject } from "../../../_utils/themeChange";
import { getGridWidth } from "../../_utils/sizeCalculations";
import { getActivityDetails } from "../../../../services/activities/service";
import { userDataStore } from "../../../../stores/userDataStore";

export default class Game extends Phaser.Scene {
  constructor() {
    super({ key: "Game" });
  }

  init() {
    new Background(this);
    this.level = undefined;
  }

  preload() {
    this.load.text("dictionary", "assets/_texts/dictionary.txt");
  }

  loadGameConfig() {
    getActivityDetails(userDataStore.currentExercise.id, userDataStore.currentActivityId).then((res) => {
      this.level = res;
      userDataStore.setCurrentActivity(this.level)
      this.renderGame();
    })
  }

  renderGame() {
    renderControlButtons(this);

    const midX = this.scale.width / 2;
    const midY = this.scale.height / 2;

    const gridWidth = getGridWidth(this);

    this.gridEngine = new GridEngine({
      scene: this,
      midX: midX,
      midY: midY,
      width: gridWidth,
      height: gridWidth,
      gameType: GAME_TYPE_WORD,
      isDarkTheme: settingStore.theme === LIGHT_THEME ? false : true,
      words: this.level.configurations.match_list.map((item) => item.word)
    });

    autorun(() => {
      this.gridEngine.themeChangeEvent.emit(
        EVENT_THEME_CHANGE,
        themeChangeEventObject
      );
    })

    this.gridEngine.setGridDimension(
      this.level.configurations.row,
      this.level.configurations.column,
      this.level.configurations.grid
    );

    this.gridEngine.addBottomSlab(this.level.description);
    this.gridEngine.setSoundVolume(settingStore.soundVolume);

    autorun(() => {
      this.gridEngine.setSoundVolume(settingStore.soundVolume);
    });

    this.eventsManager = new EventsManager(this.gridEngine);

    this.scoreManager = new ScoreManager(this.gridEngine);

    this.timerManager = new TimerManager(this.gridEngine);
    this.timerManager.setTimer(this.level.time_required);

    this.labelManager = new LabelManager(this.gridEngine);

    this.controlsManager = new ControlsManager(
      this.gridEngine,
      this.scoreManager,
      this.labelManager,
      this.timerManager
    );

    this.controlsManager.setMovements({
      disableMovements: settingStore.settingsOpen ? true : false,
      allowDrag: true,
    });

    autorun(() => {
      this.controlsManager.setMovements({
        disableMovements: settingStore.settingsOpen ? true : false,
        allowDrag: true,
      });
    });

    this.controlsManager.setSceneAfterGameOverCallback(() => {});

    this.gridEngine.gameStateEvent.on(EVENT_GAME_OVER, (gameStats) => {
      const data = {
        gameType: gameStats.gameType,
        grid: gameStats.grid,
        correctWords: gameStats.correctWords,
        moves: gameStats.moves,
        totalWords: gameStats.totalWords,
        timeTaken: gameStats.timeTaken,
        totalTime: gameStats.totalTime,
      };

      this.scene.start(SCENE_RESULT, {
        stats: calculateResults(data),
        data: data,
        gameID: GAME_ID_WORD_SEARCH,
      });
    });

    this.gridEngine.gameStateEvent.on(EVENT_GAME_RUNNING, (gameStats) => {});
  }

  create() {
    this.loadGameConfig();
  }
}
