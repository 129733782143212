import React, { useEffect } from "react";
import Phaser from "phaser";

import {
  GAME_ID_WORD_SEARCH,
  GAME_ID_ALPHABET_SEARCH,
  GAME_ID_SPOT_THE_DIFFERENCE,
  GAME_ID_NUMBER_SEARCH,
} from "@rewire-brain/grid-engine/utils/constants";

//config files
import WordSearchConfig from "../puzzles/GridProblems/WordSearch/main";
import AlphabetSearchConfig from "../puzzles/GridProblems/AlphabetSearch/main";
import NumberSearchConfig from "../puzzles/GridProblems/NumberSearch/main";
import SpotTheDifferenceConfig from "../puzzles/GridProblems/SpotTheDifference/main";
import InsertNumberConfig from "../puzzles/ArrangementProblems/InsertNumber/main";
import FindTheDifferentConfig from "../puzzles/ArrangementProblems/FindDifferentNumbers/main";

import { GAME_ID_ARRANGE_THE_NUMBERS, GAME_ID_FIND_THE_DIFFERENT } from "../utils/constants";
import { Helmet } from "react-helmet";

const GameRenderComp = ({ game }) => {
  useEffect(() => {
    const main = new Phaser.Game(getConfig(game));

    return () => {
      main.destroy(true);
    };
  }, [game]);

  const getConfig = (name) => {
    switch (name) {
      case GAME_ID_WORD_SEARCH:
        return WordSearchConfig;
      case GAME_ID_ALPHABET_SEARCH:
        return AlphabetSearchConfig;
      case GAME_ID_NUMBER_SEARCH:
        return NumberSearchConfig;
      case GAME_ID_SPOT_THE_DIFFERENCE:
        return SpotTheDifferenceConfig;
      case GAME_ID_ARRANGE_THE_NUMBERS:
        return InsertNumberConfig;
      case GAME_ID_FIND_THE_DIFFERENT:
        return FindTheDifferentConfig;
      default:
        return "";
    }
  };

  return (
    <div>
      <Helmet>
        <title>Playground | SCALE</title>
        <meta name="description" content="Leadership and followership are mindsets. They’re just different ways of looking at the world. One is reactive, and the other is proactive. Where one sees a to-do list, the other sees possibilities." />
      </Helmet>
      <div
        style={{ width: "100vw", height: "100vh" }}
        id="phaser-container"
        data-testid="phaser-container"
      />
    </div>
  );
};

export default GameRenderComp;
