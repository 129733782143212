import WordPuzzleExerciseThumbnail from "../database/exercisesThumbnails/word-puzzle.png";
import AlphabetExerciseThumbnail from "../database/exercisesThumbnails/alphabet-search.png";
import SpotTheDifferenceExerciseThumbnail from "../database/exercisesThumbnails/spot-the-difference.png";
import ArrangeTheNumbersThumbnail from "../database/exercisesThumbnails/arrange_the_numbers.png"
import FindTheDifferentThumbnail from "../database/exercisesThumbnails/find-the-different.png";
import NumberSearchThumbnail from "../database/exercisesThumbnails/number-search.png";

export const getGameHeaderImage = (game_id) => {
  const gameIdThumbnailMap = {
    1: AlphabetExerciseThumbnail,
    4: WordPuzzleExerciseThumbnail,
    5: SpotTheDifferenceExerciseThumbnail,
    6: ArrangeTheNumbersThumbnail,
    7: FindTheDifferentThumbnail,
    13: NumberSearchThumbnail
  }

  return gameIdThumbnailMap[game_id]
}