import { ExitButton } from "./buttons/ExitButton";
import { MuteButton } from "./buttons/MuteButton";
import { InfoButton } from "./buttons/InfoButton";
import { SettingsButton } from "./buttons/SettingsButton";

import { renderTimer } from "./_renderTimer";
import { renderScore } from "./_renderScore";
import { renderBottomSlab } from "./_renderBottomSlab";
import { getControlButtonPositions } from "../_utils/getButtonPositions";
import { parseStyle } from "../_utils/styleUtilities/_position";

export const renderBaseScene = (scene, gameOverCondition, gameOverCallback, props) => {
  renderControlButtons(scene, props || {});
  
  renderTimer(scene, gameOverCondition, gameOverCallback);
  renderScore(scene);
  renderBottomSlab(scene);
}

export const renderControlButtons = (scene, props = {}) => {
  const { settingsButtonX, settingsButtonY, infoButtonY } = getControlButtonPositions();
  const { 
    isInfoButton = true, 
    isSettingsButton = true, 
    isExitButton = true, 
    isMuteButton = true
  } = props
  
  if(isInfoButton)
    new InfoButton(scene, settingsButtonX, infoButtonY);

  if(isSettingsButton)
    new SettingsButton(scene, settingsButtonX, settingsButtonY);

  if(isExitButton)
    new ExitButton(scene, parseStyle("w-100") - settingsButtonX, settingsButtonY);
  
  if(isMuteButton)
    new MuteButton(scene, parseStyle("w-100") - settingsButtonX, infoButtonY);
}