import { BLACK_SHADE_0e, RED_SHADE, SCENE_BOOTSTRAP, SCENE_CHECK_WEBGL, TEXT_SIZE_MEDIUM, TEXT_SIZE_NORMAL, WHITE_SHADE } from "../../utils/constants";
import Phaser from "phaser";
import { ASSET_IMG_BACKGROUND } from "../../utils/constants/assetkeys";
import { Background } from "../GridProblems/_components/Background";
import { setGameDimension } from "../GridProblems/_utils/setGameDimension";
import { parseStyle } from "../_utils/styleUtilities/_position";
import { userDataStore } from "../../stores/userDataStore";

export default class CheckWebGL extends Phaser.Scene {
  constructor() {
    super(SCENE_CHECK_WEBGL)
  }

  preload() {
    this.load.image(ASSET_IMG_BACKGROUND, "assets/_images/background.png")
  }

  init() {
    setGameDimension(this.scale.width, this.scale.height);
  }

  create() {
    new Background(this);
    const isWebGLEnabled = this.sys.game.device.features.webGL

    if (isWebGLEnabled) {
      this.scene.start(SCENE_BOOTSTRAP);
    } else {
      this.displayWebGLMessage();
      this.setupInputHandlers()
    }
  }

  setupInputHandlers() {
    this.input.keyboard.on('keydown-ENTER', this.goBackAction, this);
    this.input.keyboard.on('keydown-SPACE', this.goBackAction, this);
    this.input.on('pointerup', this.goBackAction, this);
  }

  displayWebGLMessage() {
    const graphics = this.add.graphics();
    graphics.fillStyle(BLACK_SHADE_0e, 0.5);
    graphics.fillRect(0, 0, parseStyle("w-100"), parseStyle("h-100"));

    const MESSAGE_TEXT = "WebGL is not supported on this browser or in most cases is turned off.";
    const INSTRUCTION_TEXT = "Please enable WebGL by going to browser settings and refresh the page.";
    const BACK_ACTION_TEXT = "Hit ENTER or click anywhere to go back";
    const messageWidth = parseStyle("w-90")

    const mainMessage = this.addText(parseStyle("w-50"), parseStyle("h-50"), MESSAGE_TEXT, TEXT_SIZE_MEDIUM, RED_SHADE, messageWidth);
    const infoMessage = this.addText(parseStyle("w-50"), parseStyle("h-50") + mainMessage.getBounds().height + parseStyle("yp-2"), INSTRUCTION_TEXT, TEXT_SIZE_NORMAL, WHITE_SHADE, messageWidth);
    this.addText(
      parseStyle("w-50"), 
      parseStyle("h-50") + 
      mainMessage.getBounds().height + 
      infoMessage.getBounds().height + 
      parseStyle("yp-5"), 
      BACK_ACTION_TEXT, 
      TEXT_SIZE_NORMAL, 
      WHITE_SHADE, 
      messageWidth
    );
  }

  addText(x, y, text, fontSize, color, wrapWidth = null) {
    const textConfig = {
      fontFamily: 'Arial',
      fontSize,
      color,
      align: 'center',
      wordWrap: wrapWidth ? { width: wrapWidth } : null
    };
    return this.add.text(x, y, text, textConfig).setOrigin(0.5, 1);
  }

  goBackAction() {
    window.location.href = `/course/${userDataStore.currentCourse.id}`
  }
}