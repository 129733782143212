import { boardStore } from "../../puzzles/_stores/BoardStore"

// LevelStatus Types
export const ACTIVITY_STATUS_CURRENT = "current"
export const ACTIVITY_STATUS_ATTEMPTED = "attempted"
export const ACTIVITY_STATUS_COMPLETED = "completed"
export const ACTIVITY_STATUS_NOT_COMPLETED = "not-completed"
export const ACTIVITY_STATUS_LOCKED = "locked"

// Font Sizes
export const TEXT_SIZE_SMALL = 24 * boardStore.scaleFactor
export const TEXT_SIZE_NORMAL = 36 * boardStore.scaleFactor
export const TEXT_SIZE_NORMAL_MOB = 10 * boardStore.scaleFactor
export const TEXT_SIZE_MEDIUM = 48 * boardStore.scaleFactor
export const TEXT_SIZE_MEDIUM_MOB = 18 * boardStore.scaleFactor
export const TEXT_SIZE_LARGE = 156 * boardStore.scaleFactor
export const TEXT_SIZE_LARGE_MOB = 90 * boardStore.scaleFactor

// Devices
export const MOBILE = "MOBILE"
export const TABLET = "TABLET"
export const PC = "PC"

// Themes
export const DARK_THEME = "dark"
export const LIGHT_THEME = "white"

// Shader Keys
export const WIPE_POST_FX = "WipePostFX"
export const SHINE_POST_FX = "ShinePostFX"

// Colors
export const PURPLE_SHADE = 0x462c45
export const LIGHT_PURPLE_SHADE = 0x271C2D
export const DARK_PURPLE_SHADE = 0x2f1c2f
export const BLUE_SHADE = "#31989a"
export const BLUE_SHADE_0x = 0x31989a
export const ORANGE_SHADE = "#f08f29"
export const ORANGE_SHADE_0x = 0xf08f29
export const WHITE_SHADE_0x = 0xffffff;
export const WHITE_SHADE = "#ffffff";
export const YELLOW_SHADE = "#fed566"
export const YELLOW_SHADE_0x = 0xfed566
export const SLIDER_COLOR_LIGHT = 0x7b5e57;
export const SLIDER_COLOR_DARK = 0xa0a0a0;
export const BLACK_SHADE_0e = 0x000000;
export const BLACK_SHADE = "#000000";
export const GRAY_SHADE = 0x222222;
export const DARK_GRAY_SHADE = 0x282c34
export const TINT_SHADE_0x = 0x787878;
export const RED_SHADE = "#ff5000"

// GameScenes
export const SCENE_BOOTSTRAP = "Bootstrap"
export const SCENE_LEVEL_DESCRIPTION = "LevelDescription"
export const SCENE_GAME = "Game"
export const SCENE_MAIN_MENU = "MainMenu"
export const SCENE_RESULT = "GameResult"
export const SCENE_CHECK_WEBGL = "CheckWebGL"

export const COURSE_STATUS_NOT_STARTED = "course-not-started"
export const COURSE_STATUS_STARTED = "course-started"
export const COURSE_STATUS_COMPLETED = "course-completed"

export const EXERCISE_STATUS_NOT_STARTED = "Not Started"
export const EXERCISE_STATUS_STARTED = "In Progress"
export const EXERCISE_STATUS_COMPLETED = "Completed"

// Stores
export const DB_COURSES = "coursesDB"
export const DB_EXERCISES = "exercisesDB"
export const DB_ACTIVITIES = "activitiesDB"

// MISC
export const DICTIONARY = "dictionary"

export const GAME_ID_ARRANGE_THE_NUMBERS = "insert-number"
export const GAME_ID_FIND_THE_DIFFERENT = "find-the-different"

export const DEPTH_BACK_DROP = 6
export const EVENT_THEME_CHANGE = "theme-change"
