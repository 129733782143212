export const URL_ELITMUS = "https://www.elitmus.com"
export const URL_ELITMUS_TERMS_OF_USE = "https://www.elitmus.com/terms_of_use"
export const URL_ELITMUS_PRIVACY_POLICY = "https://www.elitmus.com/privacy_policy"
export const URL_ELITMUS_CONTACT_US = "https://www.elitmus.com/contact_us"
export const URL_ELITMUS_SUBCRIBE_GROUPS = "https://www.elitmus.com/subscribe_groups"
export const URL_ADDA_HOME = "https://adda.elitmus.com"
export const URL_ADDA_HELP = "https://adda.elitmus.com/c/help/14"
export const URL_ELITMUS_ABOUT = "https://www.elitmus.com/about_us"
export const URL_ELITMUS_KEY_MEMBERS = "https://www.elitmus.com/key_team_members"
export const URL_ELITMUS_CAREERS = "https://www.elitmus.com/careers"
export const URL_ELITMUS_SITE_MAP = "https://www.elitmus.com/site_map"
export const URL_ELITMUS_BLOG = "https://www.elitmus.com/blog"
export const URL_PAC_LABS = "https://www.elitmus.com/pac"
export const URL_ELITMUS_SOLUTIONS = "https://www.elitmus.com/solutions"
export const URL_ELITMUS_CLIENTS = "https://www.elitmus.com/jobs"
export const URL_ELITMUS_PH_TEST = "https://www.elitmus.com/ph_test"
export const URL_ELITMUS_TAKE_A_TOUR = "https://www.elitmus.com/tour"
export const URL_ELITMUS_CANDIDATES = "https://www.elitmus.com/candidates"
export const URL_ELITMUS_JOBS_INFO = "https://www.elitmus.com/great_fresher_jobs"
export const URL_ELITMUS_TESTS = "https://www.elitmus.com/tests"
export const URL_ELITMUS_JOBS = "https://www.elitmus.com/jobs"

// Social Links
export const URL_ELITMUS_INSTAGRAM = "https://www.instagram.com/elitmus.official"
export const URL_ELITMUS_YOUTUBE = "https://www.youtube.com/@elitmus"
export const URL_ELITMUS_LINKEDIN = "https://www.linkedin.com/company/elitmus-evaluation-pvt-ltd"