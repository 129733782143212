import { getDimensions } from "./_getDimensions";

export const getPlayAreaCoordinates = (scene) => {
  const { tileSize, primaryArrayX, primaryArrayY, totalWidth, totalHeight } =
    getDimensions(scene, scene.numberObjects.length, false);

  const playAreaTopLeft = {
    x: primaryArrayX - tileSize / 2,
    y: primaryArrayY - tileSize / 2,
  };

  const playAreaBottomLeft = {
    x: playAreaTopLeft.x,
    y: playAreaTopLeft.y + totalHeight,
  };

  const playAreaTopRight = {
    x: playAreaTopLeft.x + totalWidth,
    y: playAreaTopLeft.y,
  };

  const playAreaBottomRight = {
    x: playAreaTopRight.x,
    y: playAreaBottomLeft.y,
  };

  return { playAreaTopLeft, playAreaBottomLeft, playAreaTopRight, playAreaBottomRight };
};

export const getPlayAreaBounds = (scene, bufferX, bufferY) => {
  const { playAreaTopLeft, playAreaBottomLeft, playAreaTopRight } =
    getPlayAreaCoordinates(scene);

  return {
    xMin: playAreaTopLeft.x - bufferX,
    xMax: playAreaTopRight.x + bufferX,
    yMin: playAreaTopLeft.y - bufferY,
    yMax: playAreaBottomLeft.y + bufferY,
    playAreaTopLeft,
  };
};

export const isInsidePlayArea = (bg, bounds) => {
  return (
    bg.x > bounds.xMin &&
    bg.x < bounds.xMax &&
    bg.y > bounds.yMin &&
    bg.y < bounds.yMax
  );
};

export const calculateThresholdDistance = (
  bg,
  playAreaTopLeft,
  tileSize,
  tileGap,
  isHorizontal
) => {
  return isHorizontal
    ? Math.abs(bg.x - playAreaTopLeft.x) % (tileSize + tileGap)
    : Math.abs(bg.y - playAreaTopLeft.y) % (tileSize + tileGap);
};

export const calculateInsertIndex = (
  bg,
  primaryArrayX,
  primaryArrayY,
  tileSize,
  tileGap,
  isHorizontal
) => {
  return isHorizontal
    ? Math.floor((bg.x - primaryArrayX) / (tileSize + tileGap))
    : Math.floor((bg.y - primaryArrayY) / (tileSize + tileGap));
};

export const isCorrectPosition = (
  draggedTileNumber,
  prevNumber,
  nextNumber,
  isAscending,
  insertAtIndex,
  allNumbers
) => {
  if (insertAtIndex === 0) {
    return isAscending
      ? draggedTileNumber <= nextNumber
      : draggedTileNumber >= nextNumber;
  } else if (insertAtIndex === allNumbers.length) {
    return isAscending
      ? draggedTileNumber >= prevNumber
      : draggedTileNumber <= prevNumber;
  } else {
    return isAscending
      ? draggedTileNumber >= prevNumber && draggedTileNumber <= nextNumber
      : draggedTileNumber <= prevNumber && draggedTileNumber >= nextNumber;
  }
};
