import React from "react";
import { Link } from "react-router-dom";

import { menuItemsLoggedIn, menuItemsPublic } from "./_menuItemList";
import { authStore } from "../../../stores/authStore";
import MobileNav from "./_mobileNav";
import MenuItemComponent from "./_menuItemComponent";
import AccountSettings from "./_accountSettings";

import elitmusLogo from "../../../data/assets/logo.svg";
import { URL_ELITMUS } from "../../../utils/constants/elitmusURLs";

const Header = () => {
  const { authState } = authStore;

  return (
    <header
      data-testid="header"
      className="transition-all duration-150 ease-linear sticky z-10 top-0"
    >
      <div className="bg-grayShade1 py-2 px-5 flex justify-between items-center">
        <Logo />

        <nav className={`hidden sm:flex md:flex items-center ${authState ? "w-full" : "w-fit"} justify-between`}>
          {authState ? <LoggedInMenu /> : <PublicMenu />}
        </nav>

        <div className="md:hidden block">
          <MobileNav />
        </div>
      </div>
    </header>
  );
};

const Logo = () => (
  <div>
    <Link to={URL_ELITMUS}>
      <img alt="eLitmus logo" className="w-[150px]" src={elitmusLogo} />
    </Link>
  </div>
);

const PublicMenu = () => (
  <div className="flex items-center">
    {menuItemsPublic.map((item) => (
      <MenuItemComponent key={item.title} item={item} />
    ))}
  </div>
);

const LoggedInMenu = () => (
  <>
    <div className="flex items-center ml-5">
      {menuItemsLoggedIn.map((item) => (
        <MenuItemComponent key={item.title} item={item} />
      ))}
    </div>
    <AccountSettings />
  </>
);

export default Header;
