export const timerSoundThreshold = 10

export const mobileScreenDivisor = 14
export const tabletScreenDivisor = 8
export const pcScreenDivisor = 4

export const mobileBreakPoint = 600 * window.devicePixelRatio
export const tabletBreakPoint = 1200 * window.devicePixelRatio

export const MULTIPLIER_WIDTH = 0.8
export const MULTIPLIER_HEIGHT = 0.6

export const PATH_LOGO_WORD_SEARCH = "assets/_images/logos/word_search.png"
export const PATH_LOGO_NUMBER_SEARCH = "assets/_images/logos/number_search.png"
export const PATH_LOGO_ALPHABET_SEARCH = "assets/_images/logos/alphabet_search.png"
export const PATH_LOGO_SPOT_THE_DIFFERENCE = "assets/_images/logos/spot_the_difference.png"
export const PATH_LOGO_ARRANGE_THE_NUMBERS = "assets/_images/logos/arrange_the_numbers.png"
export const PATH_LOGO_FIND_THE_DIFFERENT = "assets/_images/logos/find_the_different.png"

export const PATH_COMMON_ASSET = "assets/"