import React, { useEffect, useState } from "react";
import { getGameRouteByID } from "../utils/getGameRouteByID";

import {
  EXERCISE_STATUS_COMPLETED,
  EXERCISE_STATUS_NOT_STARTED,
  EXERCISE_STATUS_STARTED,
} from "../utils/constants";

import ProgressBar from "./CustomUI/ProgressBar";
import { useNavigate } from "react-router-dom";
import { userDataStore } from "../stores/userDataStore";
import { getGameHeaderImage } from "../utils/getGameHeaderImage";
import { createExerciseAttempt } from "../services/exercises/service";

const ExerciseCard = ({ exercise }) => {
  const [gameRoute, setGameRoute] = useState("");
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate()

  const handleClick = async () => {
    userDataStore.setCurrentExercise(exercise)
    const response = await createExerciseAttempt(userDataStore.currentCourse.id, {
      exercise_attempt: {
        exercise_id: exercise.id
      } 
    })

    userDataStore.setExerciseAttemptId(response.id)
    navigate(gameRoute)
  }

  useEffect(() => {
    setGameRoute(getGameRouteByID(exercise.game_id));
    const totalLevels = exercise?.total_activities;
    const completedLevels = exercise?.activities_completed;
    const calculatedProgress = Math.round((Number(completedLevels) / totalLevels) * 100);
    
    setProgress(calculatedProgress);
  }, [exercise]);

  const renderExerciseCardFooter = (exerciseStatus) => {
    switch(exerciseStatus) {
      case EXERCISE_STATUS_NOT_STARTED:
        return (
          <p className="text-sm font-light mt-3">
            Total Levels: {exercise.total_activities}
          </p>
        )
      case EXERCISE_STATUS_STARTED:
      case EXERCISE_STATUS_COMPLETED:
        return (
          <div>
            <ProgressBar progress={progress} />
            <p className="text-sm font-light mt-3">
              Levels Completed: {exercise.activities_completed} /&nbsp;
              {exercise.total_activities}
            </p>
          </div>
        )
    }
  }

  return (
    <div
      onClick={handleClick}
      data-testid="exercise-card"
      className="relative border-[1px] border-gray-400 hover:shadow-md hover:cursor-pointer w-fit"
    >
      <img alt={exercise?.name} src={getGameHeaderImage(exercise.game_id)} />

      <div className="p-2 border-t-[1px] flex flex-col justify-between">
        <p className="text-sm font-semibold h-[40px]">{exercise?.name}</p>

        {renderExerciseCardFooter(exercise?.status)}
      </div>
    </div>
  );
};

export default ExerciseCard;
