import { 
  URL_ADDA_HELP, 
  URL_ADDA_HOME, 
  URL_ELITMUS_ABOUT, 
  URL_ELITMUS_BLOG, 
  URL_ELITMUS_CANDIDATES, 
  URL_ELITMUS_CAREERS, 
  URL_ELITMUS_CLIENTS, 
  URL_ELITMUS_CONTACT_US, 
  URL_ELITMUS_JOBS_INFO, 
  URL_ELITMUS_KEY_MEMBERS, 
  URL_ELITMUS_PH_TEST, 
  URL_ELITMUS_SITE_MAP, 
  URL_ELITMUS_SOLUTIONS, 
  URL_ELITMUS_TAKE_A_TOUR, 
  URL_PAC_LABS
} from "../../../utils/constants/elitmusURLs";

export const company = [
  {
    title: 'About us',
    path: URL_ELITMUS_ABOUT,
  },
  {
    title: 'Key Team Members',
    path: URL_ELITMUS_KEY_MEMBERS,
  },
  {
    title: 'Contact us',
    path: URL_ELITMUS_CONTACT_US,
  },
  {
    title: 'Careers',
    path: URL_ELITMUS_CAREERS,
  },
  {
    title: 'Sitemap',
    path: URL_ELITMUS_SITE_MAP,
  },
  {
    title: 'Blog',
    path: URL_ELITMUS_BLOG,
  },
];

export const employers = [
  {
    title: 'PAC Labs',
    path: URL_PAC_LABS,
  },
  {
    title: 'Why eLitmus',
    path: URL_ELITMUS_SOLUTIONS,
  },
  {
    title: 'Our Clients',
    path: URL_ELITMUS_CLIENTS,
  },
];

export const candidates = [
  {
    title: 'pH Test',
    path: URL_ELITMUS_PH_TEST,
  },
  {
    title: 'Take a tour',
    path: URL_ELITMUS_TAKE_A_TOUR,
  },
  {
    title: 'Job seekers home',
    path: URL_ELITMUS_CANDIDATES,
  },
  {
    title: 'Great Fresher jobs!',
    path: URL_ELITMUS_JOBS_INFO,
  },
];

export const community = [
  {
    title: 'Adda Home',
    path: URL_ADDA_HOME,
  },
  {
    title: 'Help',
    path: URL_ADDA_HELP,
  },
];