import React from "react";
import { Link } from "react-router-dom";

import {
  URL_ELITMUS_CONTACT_US,
  URL_ELITMUS_INSTAGRAM,
  URL_ELITMUS_LINKEDIN,
  URL_ELITMUS_PRIVACY_POLICY,
  URL_ELITMUS_SUBCRIBE_GROUPS,
  URL_ELITMUS_TERMS_OF_USE,
  URL_ELITMUS_YOUTUBE,
} from "../../../utils/constants/elitmusURLs";

const FooterBottom = () => {
  return (
    <div className="flex justify-center bg-grayShade2">
      <div className="flex md:justify-between justify-center w-[100%] max-w-[1000px] items-center md:px-5 py-2 xxs:flex-nowrap flex-wrap">
        <div className="flex flex-wrap text-center justify-center text-[14px]">
          <div>
            <p className="text-grayShade4 font-light">
              © 2005-2024, eLitmus Evaluation Pvt Ltd. |
            </p>
          </div>
          <div className="text-grayShade4 md:ml-3 md:mt-0 mt-3 flex flex-wrap">
            <Link to={URL_ELITMUS_TERMS_OF_USE}>
              <span>Terms of Use</span>
            </Link>
            <Link to={URL_ELITMUS_PRIVACY_POLICY}>
              <span className="ml-3">Privacy Policy</span>
            </Link>
            <Link to={URL_ELITMUS_CONTACT_US}>
              <span className="ml-3">Contact Us</span>
            </Link>
          </div>
        </div>

        <div className="flex md:justify-normal justify-center md:w-fit w-[50%] md:my-0 my-3">
          <a target="_blank" rel="noreferrer" className="mr-3" href={URL_ELITMUS_INSTAGRAM}>
            <div className="w-[35px] h-[35px] bg-white text-white rounded-full flex justify-center items-center text-[22px]">
              <img
                alt="instagram logo"
                className="w-[60%] rounded-full"
                src="/assets/instagram.svg"
              />
            </div>
          </a>
          <a target="_blank" rel="noreferrer" className="mr-3" href={URL_ELITMUS_YOUTUBE}>
            <div className="w-[35px] h-[35px] bg-white text-white rounded-full flex justify-center items-center text-[22px]">
              <img
                alt="youtube logo"
                className="w-[80%] rounded-full"
                src="/assets/youtube.svg"
              />
            </div>
          </a>
          <a target="_blank" rel="noreferrer" className="mr-3" href={URL_ELITMUS_LINKEDIN}>
            <div className="w-[35px] h-[35px] bg-[#067ab5] text-white rounded-full flex justify-center items-center text-[22px]">
              <img
                alt="linkedin logo"
                className="w-[80%] rounded-full"
                src="/assets/linkedin.svg"
              />
            </div>
          </a>
          <a target="_blank" className="mr-3" rel="noreferrer" href={URL_ELITMUS_SUBCRIBE_GROUPS}>
            <div className="w-[35px] overflow-hidden h-[35px] bg-white text-white rounded-full flex justify-center items-center text-[22px]">
              <img
                alt="gmail logo"
                className="w-[60%] rounded-full"
                src="/assets/gmail-icon.svg"
              />
            </div>
          </a>
          <a target="_blank" rel="noreferrer" className="mr-3" href={URL_ELITMUS_SUBCRIBE_GROUPS}>
            <div className="w-[35px] h-[35px] bg-white text-white rounded-full flex justify-center items-center text-[22px]">
              <img
                alt="telegram logo"
                className="w-[100%] rounded-full"
                src="/assets/telegram-icon.svg"
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
