import { ASSET_IMG_ICON_BACK } from "../../../../utils/constants/assetkeys"
import { createImage } from "../../../_utils/styleUtilities/_scaling"
import { setCursorToDefault, setCursorToPointer } from "../../_utils/cursorUtils"

export class BackButton {
  constructor(scene, xPos, yPos, action) {
    this.scene = scene
    this.xPos = xPos
    this.yPos = yPos
    this.action = action

    return this.createButton()
  }

  createButton() {
    const { scene, xPos, yPos, action } = this
    const closeButton = createImage(scene, xPos, yPos, ASSET_IMG_ICON_BACK, { scale: 0.5 })
      .setDepth(12)
      .setInteractive();

    closeButton.on("pointerover", () => {
      closeButton.setAlpha(0.95);
      setCursorToPointer(scene);
    });

    closeButton.on("pointerout", () => {
      closeButton.setAlpha(1);
      setCursorToDefault(scene);
    });

    closeButton.on("pointerdown", action);

    return closeButton
  }
}