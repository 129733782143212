import { getMinutes } from "../GridProblems/_utils/levelHelper";
import { applyScale, createImage, createText } from "../_utils/styleUtilities/_scaling"

import {
  ACTIVITY_STATUS_ATTEMPTED,
  ACTIVITY_STATUS_COMPLETED,
  ACTIVITY_STATUS_CURRENT,
  ACTIVITY_STATUS_LOCKED,
  BLACK_SHADE,
  SCENE_GAME,
} from "../../utils/constants";
import {
  ASSET_IMG_ICON_DONE,
  ASSET_IMG_ICON_LOCK,
  ASSET_IMG_ICON_TIMER,
  ASSET_IMG_LEVEL_COMPONENT,
} from "../../utils/constants/assetkeys";
import { parseStyle } from "../_utils/styleUtilities/_position";

import { userDataStore } from "../../stores/userDataStore";
import { createActivityAttempt } from "../../services/activities/service";

export class LevelComponent {
  constructor(scene, xPos, yPos, width, height, level) {
    this.scene = scene;
    this.type = ACTIVITY_STATUS_CURRENT;
    this.xPos = xPos;
    this.yPos = yPos;
    this.width = width;
    this.height = height;
    this.levelContainer = null;
    this.pad = applyScale(10);
    this.level = level;
    this.textSizeSmall = this.getTextSizes().textSizeSmall;
    this.textSizeLarge = this.getTextSizes().textSizeLarge;

    const latestResult = 
      this.level.result_data.length 
        ? [...this.level.result_data].sort((a, b) => b.created_at - a.created_at)[0].activity_attempt_status 
        : ACTIVITY_STATUS_CURRENT 

    switch (latestResult) {
      case ACTIVITY_STATUS_LOCKED:
        return this.createLockedLevel();
      case ACTIVITY_STATUS_ATTEMPTED:
        return this.createAttemptedLevel();
      case ACTIVITY_STATUS_COMPLETED:
        return this.createClearedLevel();
      case ACTIVITY_STATUS_CURRENT:
        return this.createCurrentLevel();
      default:
        break;
    }
  }

  getTextSizes() {
    const width = parseStyle("w-50");
    const conditions = [
      { maxWidth: 600, textSizeSmall: 30, textSizeLarge: 180 },
      { maxWidth: 750, textSizeSmall: 18, textSizeLarge: 110 },
      { maxWidth: 1000, textSizeSmall: 22, textSizeLarge: 140 },
      { maxWidth: 1200, textSizeSmall: 25, textSizeLarge: 150 },
      { maxWidth: 1400, textSizeSmall: 20, textSizeLarge: 140 },
    ];

    const { textSizeSmall, textSizeLarge } = conditions.find(
      (condition) => width < condition.maxWidth
    ) || {
      textSizeSmall: 25,
      textSizeLarge: 150,
    };

    return {
      textSizeSmall: textSizeSmall,
      textSizeLarge: textSizeLarge,
    };
  }

  createLockedLevel() {
    const { scene, width, height, xPos, yPos, textSizeSmall } = this;

    this.levelContainer = scene.add.container(xPos, yPos);

    const background = this.background();

    const lockIcon =
      createImage(scene, width / 2, height / 2, ASSET_IMG_ICON_LOCK, 1.2)
        .setOrigin(0.5, 0.5)

    const text =
      createText(scene, width / 2, (height * 3) / 4, "Clear past levels to unlock", {
        fontFamily: "Arial",
        fontSize: `${textSizeSmall}px`,
        color: BLACK_SHADE,
        align: "center",
        wordWrap: { width: width }
      })
        .setOrigin(0.5, 0);

    this.levelContainer.add(background);
    this.levelContainer.add(lockIcon);
    this.levelContainer.add(text);

    return this.levelContainer;
  }

  createCurrentLevel() {
    const {
      scene,
      width,
      height,
      xPos,
      yPos,
      pad,
      textSizeSmall,
      textSizeLarge,
    } = this;

    this.levelContainer = scene.add.container(xPos, yPos);

    const background = this.background();
    background.setInteractive();

    const topContainer = scene.add.container(pad, pad);
    const timeComponent = scene.add.container(width - 2 * pad, 2 * pad);

    const time = createText(scene, 0, 0, getMinutes(this.level.time_required), {
      fontFamily: "Arial",
      fontSize: `${textSizeSmall}px`,
      color: BLACK_SHADE,
      align: "center",
    }, { scale: 1.3 })
      .setOrigin(1, 0.5);

    const timerIcon = createImage(scene, -(time.getBounds().width + 2 * pad), 0, ASSET_IMG_ICON_TIMER)

    timeComponent.add(timerIcon);
    timeComponent.add(time);

    topContainer.add(timeComponent);

    const levelText = createText(scene, width / 2, height / 2, this.level.activity_number, {
      fontFamily: "Arial",
      fontSize: `${textSizeLarge}px`,
      color: BLACK_SHADE,
      align: "center",
      fontStyle: "bold",
    })
      .setOrigin(0.5)

    background.on("pointerdown", () => {
      userDataStore.setCurrentActivityId(this.level.id)

      createActivityAttempt(userDataStore.exerciseAttemptId, {
        activity_attempt: {
          activity_id: this.level.id
        } 
      }).then((res) => {
        userDataStore.setActivityAttemptId(res.id)
        scene.scene.start(SCENE_GAME);
      })
    });

    const text = createText(
      scene,
      width / 2,
      (height * 3) / 4,
      "Required Accuracy: " + this.level.configurations.criteria.accuracy + "%",
      {
        fontFamily: "Arial",
        fontSize: `${textSizeSmall}px`,
        color: "BLACK_SHADE",
        align: "center",
        wordWrap: { width: width * 0.90 }
      }, { scale: 1.2 })
      .setOrigin(0.5, 0);

    this.levelContainer.add(background);
    this.levelContainer.add(topContainer);
    this.levelContainer.add(levelText);
    this.levelContainer.add(text);

    return this.levelContainer;
  }

  createAttemptedLevel() {
    const { scene, width, height, xPos, yPos, pad, textSizeLarge } = this;

    this.levelContainer = scene.add.container(xPos, yPos);

    const background = this.background();
    background.setInteractive();

    const topContainer = scene.add.container(width - 2 * pad, pad);

    const cancelIcon =
      createImage(scene, 0, pad, "cancel-icon", { scale: 2 })
        .setOrigin(1, 0);

    topContainer.add(cancelIcon);

    const levelText =
      createText(scene, width / 2, height / 2, this.level.activity_number, {
        fontFamily: "Arial",
        fontSize: `${textSizeLarge}px`,
        color: "BLACK_SHADE",
        align: "center",
        fontStyle: "bold",
      })
        .setOrigin(0.5)

    const bottomContainer = scene.add.container(pad, height - 2 * pad);

    background.on("pointerdown", () => {
      userDataStore.setCurrentActivityId(this.level.id)

      createActivityAttempt(userDataStore.exerciseAttemptId, {
        activity_attempt: {
          activity_id: this.level.id
        } 
      }).then((res) => {
        userDataStore.setActivityAttemptId(res.id)
        scene.scene.start(SCENE_GAME);
      })
    });

    this.levelContainer.add(background);
    this.levelContainer.add(topContainer);
    this.levelContainer.add(levelText);
    this.levelContainer.add(bottomContainer);

    return this.levelContainer;
  }

  createClearedLevel() {
    const { scene, width, height, xPos, yPos, pad, textSizeLarge } = this;

    this.levelContainer = scene.add.container(xPos, yPos);

    const background = this.background();
    background.setInteractive();

    const topContainer = scene.add.container(width - 2 * pad, pad);

    const doneIcon =
      createImage(scene, 0, pad, ASSET_IMG_ICON_DONE, { scale: 2 })
        .setOrigin(1, 0)

    topContainer.add(doneIcon);

    const levelText =
      createText(scene, width / 2, height / 2, this.level.activity_number, {
        fontFamily: "Arial",
        fontSize: `${textSizeLarge}px`,
        color: "BLACK_SHADE",
        align: "center",
        fontStyle: "bold",
      })
        .setOrigin(0.5)

    const bottomContainer = scene.add.container(pad, height - 2 * pad);

    background.on("pointerdown", () => {
      userDataStore.setCurrentActivityId(this.level.id)

      createActivityAttempt(userDataStore.exerciseAttemptId, {
        activity_attempt: {
          activity_id: this.level.id
        } 
      }).then((res) => {
        userDataStore.setActivityAttemptId(res.id)
        scene.scene.start(SCENE_GAME);
      })
    });

    this.levelContainer.add(background);
    this.levelContainer.add(topContainer);
    this.levelContainer.add(levelText);
    this.levelContainer.add(bottomContainer);

    return this.levelContainer;
  }

  background() {
    const { scene, width, height } = this;

    const backgroundImage = createImage(scene, 0, 0, ASSET_IMG_LEVEL_COMPONENT);
    backgroundImage
      .setDisplaySize(width, height)
      .setOrigin(0);

    return backgroundImage;
  }
}
