import { isMobileDevice, isTabletDevice } from "../../_utils/deviceType";

export const getMinutes = (seconds) => {
  if (seconds > 60) {
    let minutes = seconds / 60;
    minutes = minutes.toFixed(1);
    return `${minutes} min`;
  } else {
    return `${seconds} sec`;
  }
};

export const evaluateSuccess = (required, achieved) => {
  if( required.accuracy <= achieved.accuracy ) return 'completed'
  else return 'attempted'
}

export const getColumns = () => {
  let cols = 5
  if(isMobileDevice() || isTabletDevice()) {
    cols = 2
  }

  return cols
}