import { ASSET_IMG_BACKGROUND } from "../../../utils/constants/assetkeys";
import { boardStore } from "../../_stores/BoardStore";
import { createImage } from "../../_utils/styleUtilities/_scaling";

export class Background {
  constructor(scene) {
    this.scene = scene

    this.createBackground(this.scene)
  }

  createBackground(scene) {
    const background = createImage(
      this.scene,
      boardStore.gameWidth / 2,
      boardStore.gameHeight / 2,
      ASSET_IMG_BACKGROUND
    );

    const scaleX = boardStore.gameWidth / background.width;
    const scaleY = boardStore.gameHeight / background.height;
    const scaleFactor = Math.max(scaleX, scaleY);

    background.setScale(scaleFactor);
    background.setOrigin(0.5);
    background.setPosition(boardStore.gameWidth / 2, boardStore.gameHeight / 2);
  }
}