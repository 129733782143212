import { autorun } from "mobx";
import { MOBILE, PC, TABLET } from "../../../../../utils/constants";
import { sizeConstantsStore } from "../../../../_stores/SizeConstantsStore";
import { isPCDevice } from "../../../../_utils/deviceType";
import { MULTIPLIER_HEIGHT, MULTIPLIER_WIDTH } from "../../../../_utils/constants";

export const getDimensions = (scene, numTiles, isDraggable) => {
  let gameWidth = scene.scale.width;
  let gameHeight = scene.scale.height;

  let primaryArrayX, primaryArrayY, draggableArrayX, draggableArrayY;

  let isHorizontal = undefined;

  autorun(() => {
    isHorizontal = isPCDevice() ? true : false;
  })

  let totalTiles = (scene.level.configurations.numbers.length + scene.level.configurations.draggables.length)

  let tileSize = isHorizontal 
      ? (gameWidth * MULTIPLIER_WIDTH - scene.tileGap * (totalTiles - 1)) / totalTiles
      : (gameHeight * MULTIPLIER_HEIGHT - scene.tileGap * (totalTiles - 1)) / totalTiles;

  const totalWidth = isHorizontal ? numTiles * tileSize + (numTiles - 1) * scene.tileGap : tileSize;
  const totalHeight = isHorizontal ? tileSize : numTiles * tileSize + (numTiles - 1) * scene.tileGap;

  switch (sizeConstantsStore.device) {
    case PC:
      primaryArrayX = (gameWidth - totalWidth) / 2 + tileSize / 2;
      primaryArrayY = gameHeight / 3;
      draggableArrayX = (gameWidth - totalWidth) / 2 + tileSize / 2;
      draggableArrayY = (gameHeight * 2) / 3 - tileSize / 2;
      break;
    case MOBILE:
    case TABLET:
      primaryArrayX = gameWidth / 3;
      primaryArrayY = (gameHeight - totalHeight) / 2 + tileSize / 2;
      draggableArrayX = (gameWidth * 2) / 3;
      draggableArrayY = (gameHeight - totalHeight) / 2 + tileSize / 2;
      break;
    default:
      break;
  }

  const commonProperties = {
    tileSize, 
    gameHeight, 
    gameWidth,
    totalHeight,
    totalWidth,
    isHorizontal,
  }

  const specificProperties = isDraggable ? { draggableArrayX, draggableArrayY } : { primaryArrayX, primaryArrayY }

  return {...specificProperties, ...commonProperties};
};
