export const activityApi = {
  list(course_id, exercise_id) {
    return `/v1/api/learner/course/${course_id}/exercise/${exercise_id}/activities`;
  },
  details(exercise_id, activity_id) {
    return `/v1/api/learner/exercise/${exercise_id}/activity/${activity_id}`;
  },
  createAttempt(exercise_attempt_id) {
    return `/v1/api/learner/exercise_attempt/${exercise_attempt_id}/activity_attempt`;
  },
  createMetaData(activity_attempt_id) {
    return `/v1/api/learner/activity_attempt/${activity_attempt_id}/result`;
  },
};
