import React from "react";

const TabBar = ({ tabMenu, tabState, setTabState }) => {
  return (
    <div className="flex">
      {tabMenu.map((menu) => {
        return (
          <div key={menu.name} role="presentation" onClick={() => setTabState(menu.slug)} className={`px-5 py-2 ${tabState === menu.slug ? 'border-white text-white' : 'border-transparent text-gray-300 cursor-pointer'} border-b-[5px]`}>
            <p>{menu.name}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TabBar;