import {
  MOVE_STATUS_CORRECT,
  MOVE_STATUS_INCORRECT,
  MOVE_STATUS_SAME,
} from "@rewire-brain/grid-engine/utils/constants";

import { soundPlayer } from "../../../../_components/soundPlayer";
import { getDimensions } from "./_getDimensions";
import { convertTo2D, getCommonNumber, getCorrectMovesCount } from "./_helpers";
import { getTile } from "../../../../_components/getTile";

import {
  BLACK_SHADE,
} from "../../../../../utils/constants";

import { changeFontColorWithTheme } from "../../../../_utils/themeChange";

export const renderNumbers = (scene) => {
  const { level } = scene;

  const grid = convertTo2D(level.configurations.grid);
  const commonNumber = getCommonNumber(grid);

  const { rows, cols, startX, startY, tileSize } = getDimensions(scene, grid);

  let x = null;
  let y = null;

  for (let i = 0; i < rows; i++) {
    for (let j = 0; j < cols; j++) {
      const number = Number(grid[i][j]);

      x = startX + j * (tileSize + scene.tileGap);
      y = startY + i * (tileSize + scene.tileGap);

      createTile(
        scene,
        x,
        y,
        tileSize,
        number,
        commonNumber,
        grid
      );

      const numberObject = createNumberObject(scene, x, y, tileSize, number);
      scene.numberObjects.push({ numberObject, x: j, y: i });
    }
  }
};

const handlePointerDown = (scene, grid, commonNumber, number, x, y) => {
  const move = {
    status: "",
    x: x,
    y: y,
    position: grid.length === 1 ? parseInt(x) : [parseInt(x), parseInt(y)],
    number: number
  };

  const alreadyClicked = scene.moves.some(
    (move) => move.x === x && move.y === y
  );

  if (number !== commonNumber && !alreadyClicked) {
    handleCorrectMove(scene, x, y);
    move.status = MOVE_STATUS_CORRECT;
    soundPlayer(scene).success.play();
  } else if (number !== commonNumber && alreadyClicked) {
    move.status = MOVE_STATUS_SAME;
    soundPlayer(scene).repeat.play();
  } else {
    handleIncorrectMove(scene);
    move.status = MOVE_STATUS_INCORRECT;
    soundPlayer(scene).failure.play();
  }

  scene.moves.push(move);
  scene.totalCorrectMoves = getCorrectMovesCount(grid, commonNumber);
};

const handleCorrectMove = (scene, x, y) => {
  scene.tweens.add({
    targets: [scene.scoreText],
    scaleX: 1.2,
    scaleY: 1.2,
    duration: 100,
    yoyo: true,
    onComplete: () => {
      scene.scoreText.setText(scene.score);
    },
  });

  scene.correctMoves.push({ x, y });
  scene.score += 10;
};

const handleIncorrectMove = (scene) => {
  scene.tweens.add({
    targets: [scene.scoreText],
    scaleX: 1.2,
    scaleY: 1.2,
    duration: 100,
    yoyo: true,
    onComplete: () => {
      scene.scoreText.setText(scene.score);
    },
  });

  scene.score -= 2;
};

const createTile = (scene, x, y, tileSize, number, commonNumber, grid) => {
  const tile = getTile(scene, x, y, tileSize, true);
  tile.setInteractive();
  tile.on("pointerdown", () => {
    handlePointerDown(scene, grid, commonNumber, number, x, y);
  });

  return tile;
};

const createNumberObject = (scene, x, y, tileSize, number) => {
  const numberString = number.toString();
  const digitCount = numberString.length;
  let baseFontSize = tileSize / 3;

  let fontSize =
    baseFontSize * Math.pow(1 , digitCount) + "px";
  
  const numberObject = 
    scene.add.text(x, y, number, {
      fontFamily: "Arial",
      fontStyle: "bold",
      fontSize: fontSize,
      color: BLACK_SHADE,
    })
    .setOrigin(0.5);

  changeFontColorWithTheme(numberObject);

  return numberObject;
};
