import Phaser from "phaser";
import { autorun } from "mobx";

import { settingStore } from "../_stores/SettingsStore";
import { boardStore } from "../_stores/BoardStore";

import {
  SCENE_LEVEL_DESCRIPTION,
  SCENE_MAIN_MENU,
  SHINE_POST_FX,
  TEXT_SIZE_NORMAL,
  YELLOW_SHADE,
} from "../../utils/constants";

import {
  ASSET_AUDIO_BGM,
  ASSET_IMG_LOGO,
  ASSET_IMG_PLAY_BUTTON,
} from "../../utils/constants/assetkeys";
import { Background } from "../GridProblems/_components/Background";
import { setGameDimension } from "../GridProblems/_utils/setGameDimension";
import { renderControlButtons } from "../_components/_renderBaseScene";
import { parseStyle } from "../_utils/styleUtilities/_position";
import { applyScale, createImage, createText } from "../_utils/styleUtilities/_scaling";

export default class MainMenu extends Phaser.Scene {
  constructor() {
    super(SCENE_MAIN_MENU);
  }

  init() {
    setGameDimension(this.scale.width, this.scale.height);
    new Background(this);
  }

  create() {
    const logo = createImage(
      this,
      parseStyle("w-50"),
      -parseStyle("h-50"),
      ASSET_IMG_LOGO, {
      scale_mb: 1,
      scale_tb: 1,
      scale_pc: 2
    })

    const backgroundMusic = this.sound.add(ASSET_AUDIO_BGM);
    backgroundMusic.play({
      loop: true,
    });

    backgroundMusic.setVolume(settingStore.musicVolume);

    autorun(() => {
      backgroundMusic.setVolume(settingStore.musicVolume);
    });

    this.sound.mute = true

    renderControlButtons(this);

    const playButton = createImage(
      this,
      boardStore.gameWidth / 2,
      boardStore.gameHeight / 2 + logo.getBounds().height / 2,
      ASSET_IMG_PLAY_BUTTON, {
      scale: 1.5
    }
    )
      .setDepth(2)
      .setInteractive();

    playButton.on("pointerover", () => {
      this.input.setDefaultCursor("pointer");
    });

    playButton.on("pointerout", () => {
      this.input.setDefaultCursor("default");
    });

    const helperText = createText(
      this,
      boardStore.gameWidth / 2,
      playButton.getBounds().y + playButton.getBounds().height + 10,
      "or Press Enter / Spacebar",
      {
        align: "center",
        strokeThickness: 1,
        fontSize: TEXT_SIZE_NORMAL,
        fontStyle: "bold",
        color: YELLOW_SHADE,
      }
    )
      .setOrigin(0.5)
      .setDepth(2)
      .setInteractive()

    playButton.visible = false;
    helperText.visible = false;

    logo.setPostPipeline(SHINE_POST_FX);

    this.tweens.add({
      targets: playButton,
      scaleX: applyScale(1.2),
      scaleY: applyScale(1.2),
      duration: 1000,
      ease: "Quad.easeIn",
      yoyo: true,
      repeat: -1,
    });

    this.tweens.add({
      targets: logo,
      y: parseStyle("h-40"),
      x: parseStyle("w-50"),
      delay: 200,
      duration: 2000,
      ease: "sine.out",
      onComplete: () => {
        playButton.visible = true;
        helperText.visible = true;
      },
    });

    autorun(() => {
      if (settingStore.settingsOpen || settingStore.infoModalOpen) {
        this.disableKeyboardActions(this);
      } else {
        this.enableKeyboardActions(this);
      }
    })

    playButton.on("pointerdown", () => {
      this.tweens.add({
        targets: logo,
        alpha: 0,
        duration: 1000,
        ease: "sine.out",
      });

      this.scene.start(SCENE_LEVEL_DESCRIPTION);
    });
  }

  enableKeyboardActions(scene) {
    scene.input.keyboard.on('keydown-ENTER', () => {
      this.keyPressedAction(scene);
    });

    scene.input.keyboard.on('keydown-SPACE', () => {
      this.keyPressedAction(scene);
    });
  }

  disableKeyboardActions(scene) {
    scene.input.keyboard.off('keydown-ENTER')
    scene.input.keyboard.off('keydown-SPACE')
  }

  keyPressedAction(scene) {
    scene.scene.start(SCENE_LEVEL_DESCRIPTION);
  }
}
