import {
  MOVE_STATUS_CORRECT,
  MOVE_STATUS_INCORRECT,
} from "@rewire-brain/grid-engine/utils/constants";
import { soundPlayer } from "../../../../_components/soundPlayer";
import { fontStyle } from "./_renderPrimaryNumbers";
import { getDimensions } from "./_getDimensions";
import { getTile } from "../../../../_components/getTile";
import { isMobileDevice } from "../../../../_utils/deviceType";
import {
  calculateInsertIndex,
  calculateThresholdDistance,
  getPlayAreaBounds,
  isCorrectPosition,
  isInsidePlayArea,
} from "./_helpers";
import { changeFontColorWithTheme } from "../../../../_utils/themeChange";
import { TEXT_SIZE_MEDIUM, WHITE_SHADE } from "../../../../../utils/constants";

export const renderDraggableNumbers = (scene, isAscending) => {
  const additionalNumbers = scene.level.configurations.draggables;
  const { tileSize, draggableArrayX, draggableArrayY, gameWidth } =
    getDimensions(scene, additionalNumbers.length, true);

  scene.additionalArrayLabel = scene.add
    .text(
      isMobileDevice() ? draggableArrayX : gameWidth / 2,
      draggableArrayY - tileSize / 2 - TEXT_SIZE_MEDIUM,
      "Draggable Tiles",
      {
        fontSize: TEXT_SIZE_MEDIUM,
        color: WHITE_SHADE,
        fontFamily: "Arial",
      }
    )
    .setOrigin(0.5);

  scene.draggableNumbers = [];

  additionalNumbers.forEach((number, index) => {
    const startX = isMobileDevice()
      ? draggableArrayX
      : draggableArrayX + index * (tileSize + scene.tileGap);
    const startY = isMobileDevice()
      ? draggableArrayY + index * (tileSize + scene.tileGap)
      : draggableArrayY;

    const bg = getTile(scene, startX, startY, tileSize);
    bg.setDepth(1);
    bg.setInteractive({ draggable: true });

    let numberText = scene.add
      .text(startX, startY, number, fontStyle())
      .setOrigin(0.5)
      .setDepth(2);

    changeFontColorWithTheme(numberText);

    bg.originalX = startX;
    bg.originalY = startY;
    bg.active = true;

    scene.input.setDraggable(bg);

    bg.on("drag", (pointer, dragX, dragY) => {
      if (!bg.active) return;
      bg.setDepth(3);
      numberText.setDepth(4);
      bg.x = dragX;
      bg.y = dragY;
      numberText.x = dragX;
      numberText.y = dragY;
    });

    bg.on("dragend", (pointer, dragX, dragY) => {
      if (!bg.active) return;
      bg.setDepth(1);
      numberText.setDepth(2);
      insertNumber(scene, numberText, bg, isAscending);
    });

    scene.draggableNumbers.push({ bg, numberText });
  });
};

const insertNumber = (scene, numberText, bg, isAscending) => {
  const { tileSize, primaryArrayX, primaryArrayY, isHorizontal } =
    getDimensions(scene, scene.numberObjects.length, false);

  const allNumbers = scene.numberObjects.map((obj) =>
    parseInt(obj.numberText.text)
  );
  const draggedTileNumber = parseInt(numberText.text);

  allNumbers.sort((a, b) => (isAscending ? a - b : b - a));

  const move = {
    status: MOVE_STATUS_INCORRECT,
    number: draggedTileNumber,
    position: null
  };

  const bufferX = tileSize + scene.tileGap;
  const bufferY = tileSize / 3;

  const bounds = getPlayAreaBounds(scene, bufferX, bufferY);

  if (isInsidePlayArea(bg, bounds)) {
    let thresholdDistance = calculateThresholdDistance(
      bg,
      bounds.playAreaTopLeft,
      tileSize,
      scene.tileGap,
      isHorizontal
    );

    let calculatedIndex = calculateInsertIndex(
      bg,
      primaryArrayX,
      primaryArrayY,
      tileSize,
      scene.tileGap,
      isHorizontal
    );

    let prevNumber, nextNumber, insertAtIndex;
    if (calculatedIndex < 0) {
      nextNumber = allNumbers[0];
      insertAtIndex = 0;
    } else if (calculatedIndex >= allNumbers.length) {
      prevNumber = allNumbers[allNumbers.length - 1];
      insertAtIndex = allNumbers.length;
    } else {
      prevNumber = allNumbers[calculatedIndex];
      nextNumber = allNumbers[calculatedIndex + 1];
      insertAtIndex = calculatedIndex + 1;
    }

    const correctPosition = isCorrectPosition(
      draggedTileNumber,
      prevNumber,
      nextNumber,
      isAscending,
      insertAtIndex,
      allNumbers
    );

    const thresholdCondition =
      thresholdDistance <= tileSize * 0.3 ||
      thresholdDistance >= tileSize * 0.7;

    move.position = insertAtIndex;

    handleMove(
      scene,
      move,
      bg,
      numberText,
      insertAtIndex,
      isAscending,
      correctPosition,
      thresholdCondition,
      allNumbers
    );
  } else {
    resetTilePosition(scene, bg, numberText);
  }

  scene.moves.push(move);
};

const handleMove = (
  scene,
  move,
  bg,
  numberText,
  insertAtIndex,
  isAscending,
  correctPosition,
  thresholdCondition,
  allNumbers
) => {
  const isEdgeCase = insertAtIndex === 0 || insertAtIndex === allNumbers.length;

  if (isEdgeCase || thresholdCondition) {
    if (correctPosition) {
      correctMove(scene, move, bg, numberText, insertAtIndex, isAscending);
    } else {
      wrongMove(scene, move, bg, numberText);
      move.status = MOVE_STATUS_INCORRECT;
    }
  } else {
    resetTilePosition(scene, bg, numberText);
  }
};

const correctMove = (scene, move, bg, numberText, index, isAscending) => {
  bg.active = false;
  numberText.active = false;
  scene.numberObjects.splice(index, 0, { bg, numberText });
  updateNumberPositions(scene);

  soundPlayer(scene).success.play();

  scene.score += 10;
  scoreAnimation(scene);
  move.status = MOVE_STATUS_CORRECT;
};

const scoreAnimation = (scene) => {
  scene.tweens.add({
    targets: scene.scoreText,
    scaleX: 1.2,
    scaleY: 1.2,
    duration: 100,
    yoyo: true,
    onComplete: () => {
      scene.scoreText.setText(scene.score);
    },
  });
};

const wrongMove = (scene, move, bg, numberText) => {
  scene.score -= 2;
  soundPlayer(scene).failure.play();
  scoreAnimation(scene);
  resetTilePosition(scene, bg, numberText);
  move.status = MOVE_STATUS_INCORRECT;
};

const resetTilePosition = (scene, bg, numberText) => {
  scene.tweens.add({
    targets: [bg, numberText],
    x: bg.originalX,
    y: bg.originalY,
    duration: 300,
    ease: "Power2",
  });
};

const updateNumberPositions = (scene) => {
  const tileSize = getDimensions(
    scene,
    scene.numberObjects.length,
    false
  ).tileSize;

  const { primaryArrayX, primaryArrayY, isHorizontal } = getDimensions(
    scene,
    scene.numberObjects.length,
    false
  );

  scene.numberObjects.forEach((obj, index) => {
    const { bg, numberText } = obj;
    scene.tweens.add({
      targets: [bg, numberText],
      x: isHorizontal
        ? primaryArrayX + index * (tileSize + scene.tileGap)
        : scene.scale.width / 3,
      y: isHorizontal
        ? scene.scale.height / 3
        : primaryArrayY + index * (tileSize + scene.tileGap),
      duration: 300,
      ease: "Power2",
    });
  });

  scene.tweens.add({
    targets: [scene.primaryArrayLabel],
    x: !isHorizontal ? primaryArrayX : scene.scale.width / 2,
    y: isHorizontal
      ? primaryArrayY - tileSize / 2 - 48
      : primaryArrayY -
        ((scene.numberObjects.length - scene.level.configurations.numbers.length) *
          tileSize) /
          2,
    duration: 300,
    ease: "Power2",
  });
};
