import { GAME_TYPE_LETTER } from "@rewire-brain/grid-engine/utils/constants";
import { GAME_ID_ARRANGE_THE_NUMBERS, SCENE_RESULT } from "../../../../../utils/constants";
import { calculateResults } from "../../../../GridProblems/_utils/resultsHelper";

export const gameOverCallback = (scene) => {
  const data = {
    gameType: GAME_TYPE_LETTER,
    mainArray: scene.level.configurations.numbers,
    additionalArray: scene.level.configurations.draggables,
    moves: scene.moves,
    timeTaken: scene.level.time_required - scene.timeLeft,
    totalTime: scene.level.time_required,
  }

  scene.scene.start(SCENE_RESULT, {
    data: data,
    stats: calculateResults(data),
    gameID: GAME_ID_ARRANGE_THE_NUMBERS
  });
}