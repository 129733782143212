import Phaser from "phaser";
import { createLoadingScreen } from "../../../_utils/gameLoader";
import { loadCommonAssets } from "../../../_utils/assets";

import {
  PATH_COMMON_ASSET,
  PATH_LOGO_ARRANGE_THE_NUMBERS,
} from "../../../_utils/constants";

import { ASSET_IMG_LOGO } from "../../../../utils/constants/assetkeys";
import { GAME_ID_ARRANGE_THE_NUMBERS, SCENE_BOOTSTRAP, SCENE_MAIN_MENU } from "../../../../utils/constants";
import { boardStore } from "../../../_stores/BoardStore";

export default class Bootstrap extends Phaser.Scene {
  constructor() {
    super({ key: SCENE_BOOTSTRAP });
  }

  preload() {
    createLoadingScreen(this);
    this.loadGameAssets();
  }

  create() {
    this.createNewGame();
    boardStore.gameID = GAME_ID_ARRANGE_THE_NUMBERS;
  }

  loadGameAssets() {
    this.load.image(ASSET_IMG_LOGO, PATH_LOGO_ARRANGE_THE_NUMBERS);
    loadCommonAssets(this, PATH_COMMON_ASSET);
  }

  createNewGame() {
    this.scene.launch(SCENE_MAIN_MENU);
  }
}
