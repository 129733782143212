import React from 'react';
import { Link } from 'react-router-dom';

const ListComponent = ({ title, list }) => (
  <div className="mt-3">
    <h4 className="text-[20px] font-medium">{title}</h4>

    <div className="font-light mt-2 text-[15px]">
      {list.map((listItem) => (
        <Link to={listItem.path}>
          <p className="mb-1 w-fit">{listItem.title}</p>
        </Link>
      ))}
    </div>
  </div>
);

export default ListComponent;