import { autorun } from "mobx";
import { ASSET_IMG_WHITE_TILE, ASSET_IMG_DARK_TILE } from "../../utils/constants/assetkeys";
import { settingStore } from "../_stores/SettingsStore";
import { TINT_SHADE_0x } from "../../utils/constants";
import { setCursorToDefault, setCursorToPointer } from "../GridProblems/_utils/cursorUtils";
import { createImage } from "../_utils/styleUtilities/_scaling";

export const getTile = (scene, startX, startY, tileSize, isTintOnClick = false) => {
  const bg = createImage(scene, startX, startY, ASSET_IMG_WHITE_TILE)
    .setOrigin(0.5, 0.5)
    .setDisplaySize(tileSize, tileSize)
    .setInteractive();

  if(isTintOnClick) {
    bg.on("pointerdown", () => {
      bg.setTint(TINT_SHADE_0x);
    })
  
    bg.on("pointerup", () => {
      bg.clearTint();
    })
  }

  bg.on("pointerover", () => {
    setCursorToPointer(scene);
  })

  bg.on("pointerout", () => {
    setCursorToDefault(scene);
    bg.clearTint();
  })

  autorun(() => {
    if (settingStore.theme === "dark") {
      bg.setTexture(ASSET_IMG_DARK_TILE);
    } else {
      bg.setTexture(ASSET_IMG_WHITE_TILE);
    }
  });

  return bg;
};