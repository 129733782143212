import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import GameRenderComp from "./components/GameRenderComp";
import { gameRoutes } from "./routes/gameRoutes";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import { routes } from "./routes";
import CoursePage from "./pages/CoursePage";
import { observer } from "mobx-react-lite";
import Courses from "./pages/Courses";
import LandingPage from "./pages/LandingPage";
import AuthPage from "./pages/AuthPage";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path={routes.getHomeRoute()} element={<LandingPage />} />
          <Route path={routes.getAuthFailureRoute()} element={<AuthPage status={"failure"} />} />
          <Route path={routes.getAuthSuccessRoute()} element={<AuthPage status={"success"} />} />

          <Route element={<ProtectedRoute />}>
            {gameRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<GameRenderComp game={route.game} />}
              />
            ))}
            <Route path={routes.getCoursesRoute()} element={<Courses />} />
            <Route path={routes.getCourseDetailsRoute()} element={<CoursePage />} />
          </Route>
        </Routes>
      </Layout>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default observer(App);
