import React from 'react'
import { Outlet } from "react-router-dom";
import { authStore } from '../stores/authStore'
import { loginWithSSO } from "../services/auth/service";

const ProtectedRoute = () => {
  const authState = authStore.authState

  if (!authState) {
    loginWithSSO()
    return null;
  }

  return <Outlet />;
}

export default ProtectedRoute