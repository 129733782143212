import React from "react";
import { Link } from "react-router-dom";

const LinkButton = ({ children, path, testId, textSize = "text-sm", color = "blue", ...props }) => {
  const containerClasses = 
    `px-2 py-1 rounded-md ${textSize} w-full h-full 
      flex justify-center items-center text-center 
    bg-${color}-shade text-white`;

  return (
    <div className="w-full h-full">
      <Link data-testid={testId} to={path}>
        <div className={containerClasses}>
          <p>{children}</p>
        </div>
      </Link>
    </div>
  );
};

export default LinkButton;
