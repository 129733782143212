import React from 'react'
import { MdOutlineKeyboardBackspace } from "react-icons/md"

const BackButton = ({ onClick }) => {
  return (
    <div 
      onClick={onClick} 
      className="flex items-center mb-5 bg-opacity-0 bg-white w-fit 
      px-3 py-1 hover:bg-opacity-10 cursor-pointer"
    >
      <MdOutlineKeyboardBackspace />
      <p className="ml-2">Go Back</p>
    </div>
  )
}

export default BackButton