import { ASSET_IMG_TIMER_BADGE } from "../../utils/constants/assetkeys";

import { soundPlayer } from "./soundPlayer";
import { isPCDevice } from "../_utils/deviceType";
import { BLACK_SHADE } from "../../utils/constants";
import { getControlButtonPositions } from "../_utils/getButtonPositions";
import { createImage, createText } from "../_utils/styleUtilities/_scaling"
import { parseStyle } from "../_utils/styleUtilities/_position"

export const renderTimer = (scene, condition, gameOverCallback) => {
  const { settingsButtonX, settingsButtonY } = getControlButtonPositions()
  let offset = {
    y: isPCDevice() ? 0 : parseStyle("h-10"),
    x: isPCDevice() ? parseStyle("w-25") : settingsButtonX
  }

  const timerX = parseStyle("w-100") - offset.x
  const timerY = settingsButtonY + offset.y;

  const timerBadge = createImage(scene, timerX, timerY, ASSET_IMG_TIMER_BADGE)
    .setOrigin(1, 0.5)
    .setDepth(2)

  scene.timerText = createText(
    scene,
    timerX - (timerBadge.getBounds().width / 2),
    timerY,
    formatTime(scene.timeLeft),
    {
      fontSize: "42px",
      fill: BLACK_SHADE,
    }
  )
    .setDepth(3)
    .setOrigin(0, 0.5);

  startTimer(scene, condition, gameOverCallback);
};

const startTimer = (scene, condition, gameOverCallback) => {
  scene.timer = scene.time.addEvent({
    delay: 1000,
    callback: () => {
      updateTimer(scene, condition, gameOverCallback);
    },
    callbackScope: this,
    loop: true,
  });
};

const updateTimer = (scene, condition, gameOverCallback) => {
  scene.timeLeft--;
  scene.timerText.setText(formatTime(scene.timeLeft));

  if (scene.timeLeft === 10) soundPlayer(scene).timer.play();

  if (condition()) {
    scene.timer.destroy();
    soundPlayer(scene).timer.pause();
    soundPlayer(scene).completed.play();

    gameOverCallback(scene)
  }
};

export const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedSeconds =
    remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds;
  return minutes + ":" + formattedSeconds;
};
