import Phaser from "phaser";
import { SCENE_GAME } from "../../../../../utils/constants";
import { Background } from "../../../../GridProblems/_components/Background";
import { renderNumbers } from "./_renderNumbers";
import { gameOverCallback } from "./_gameOver";
import { renderBaseScene } from "../../../../_components/_renderBaseScene";
import { userDataStore } from "../../../../../stores/userDataStore";
import { getActivityDetails } from "../../../../../services/activities/service";

export default class Game extends Phaser.Scene {
  constructor() {
    super(SCENE_GAME);
  }

  init(data) {
    this.level = undefined;
    this.timeLeft = undefined;

    this.score = 0;
    this.moves = [];
    this.timer = null;
    this.timerText = null;

    this.numberObjects = [];
    this.correctMoves = [];
    this.totalCorrectMoves = 0;

    this.tileGap = 10;
  }

  preload() {
    new Background(this);
  }

  loadGameConfig() {
    getActivityDetails(userDataStore.currentExercise.id, userDataStore.currentActivityId).then((res) => {
      this.level = res;
      this.goal = "Find the Odd Ones"
      userDataStore.setCurrentActivity(this.level)
      this.timeLeft = this.level.time_required
      this.renderGame();
    })
  }

  renderGame() {
    renderNumbers(this);

    const gameOverCondition = () => {
      return this.timeLeft <= 0 || (this.correctMoves.length === this.totalCorrectMoves && this.moves.length !== 0)
    }

    renderBaseScene(this, gameOverCondition, gameOverCallback, {
      isExitButton: true, 
      isMuteButton: true,
      isInfoButton: true, 
      isSettingsButton: true
    })
  }

  create() {
    this.loadGameConfig();
  }
}
