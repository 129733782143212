import { routes } from "../routes";

const gameRouteMap = {
  1: routes.getAlphabetSearchRoute,
  4: routes.getWordSearchRoute,
  5: routes.getSpotTheDifferenceRoute,
  6: routes.getArrangeTheNumbersRoute,
  7: routes.getFindTheDifferentRoute,
  13: routes.getNumberSearchRoute,
};

export const getGameRouteByID = (gameID) => {
  const routeFunction = gameRouteMap[gameID];
  return routeFunction ? routeFunction() : null;
};
