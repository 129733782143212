import {
  COURSE_STATUS_STARTED,
  COURSE_STATUS_NOT_STARTED,
  COURSE_STATUS_COMPLETED,
} from "./constants";

export const getCourseStatusFromProgress = (progress = 0) => {
  if(progress) {
    return COURSE_STATUS_STARTED
  } else if (progress === 100) {
    return COURSE_STATUS_COMPLETED
  } else {
    return COURSE_STATUS_NOT_STARTED
  }
}