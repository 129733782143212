import React, { useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { authStore } from "../stores/authStore";
import { showErrorToast, showSuccessToast } from "../components/toasts";

const AuthPage = ({ status }) => {
  const navigate = useNavigate();

  const handleAuth = useCallback(() => {
    if (status === "success") {
      authStore.setAuth(true);
      navigate("/courses");
      showSuccessToast("Logged in Successfully");
    } else if (status === "failure") {
      authStore.setAuth(false);
      navigate("/");
      showErrorToast("Something went wrong. Try Again!");
    }
  }, [status, navigate]);

  useEffect(() => {
    handleAuth();
  }, [handleAuth]);

  return (
    <div className="flex justify-center items-center h-full">
      <div className="loader"></div>
    </div>
  );
};

export default AuthPage;
