import Phaser from "phaser";

import { boardStore } from "../../_stores/BoardStore";
import { getParameters } from "../../GridProblems/_utils/getParameters";

import {
  SCENE_LEVEL_DESCRIPTION,
  ORANGE_SHADE_0x,
  PURPLE_SHADE,
  SCENE_RESULT,
  TEXT_SIZE_MEDIUM,
  TEXT_SIZE_NORMAL,
  WIPE_POST_FX,
  BLACK_SHADE,
  WHITE_SHADE,
  DARK_PURPLE_SHADE,
  WHITE_SHADE_0x,
} from "../../../utils/constants";

import {
  GAME_ID_WORD_SEARCH,
} from "@rewire-brain/grid-engine/utils/constants";

import {
  ASSET_IMG_BUTTON_CONTINUE,
  ASSET_IMG_RESULTS_BANNER,
} from "../../../utils/constants/assetkeys";

import { Background } from "../../GridProblems/_components/Background";
import { createWidgetBackground } from "../../GridProblems/_components/WidgetBackground";
import { isMobileDevice, isPCDevice, isTabletDevice } from "../../_utils/deviceType";
import { getScoreString } from "./_getScoreString";
import { renderControlButtons } from "../../_components/_renderBaseScene";
import { parseStyle } from "../../_utils/styleUtilities/_position";
import { applyScale, createImage, createText } from "../../_utils/styleUtilities/_scaling";
import { createResultMetaData } from "../../../services/activities/service";
import { userDataStore } from "../../../stores/userDataStore";
import { getResultPayload } from "./_getResultPayload";

export default class GameResult extends Phaser.Scene {
  constructor() {
    super(SCENE_RESULT);
  }

  init(data) {
    this.leftBuffer = boardStore.leftBuffer;
    this.topBuffer = boardStore.topBuffer;
    this.boardWidth = boardStore.boardWidth;
    this.boardHeight = boardStore.boardHeight;
    this.charMatrix = data.charMatrix;
    this.data = data.data;
    this.stats = data.stats;
    this.gameID = data.gameID;

    new Background(this);
  }

  async replayButtonAction() {
    const parsedPayload = getResultPayload(this.stats, this.data);
    const response = await createResultMetaData(userDataStore.activityAttemptId, parsedPayload)
    this.game.events.emit("refetch-activities")
  }

  renderScoreResult(startX, startY, boardHeight, boardWidth) {
    const widgetContainer = this.add.container(startX, startY);

    const resultsBanner = createImage(
      this,
      boardWidth / 2,
      0,
      ASSET_IMG_RESULTS_BANNER
    )

    widgetContainer.add(resultsBanner);

    const topContainer = this.add.container(
      0,
      resultsBanner.getBounds().height / 2
    );

    widgetContainer.add(topContainer);

    let scoreString = getScoreString(this.data, this.stats, this.gameID);

    const scoreText = createText(this, boardWidth / 2, 10, scoreString, {
      fontSize: TEXT_SIZE_MEDIUM,
      color: WHITE_SHADE,
      align: "center",
      fontStyle: "bold",
    })
      .setOrigin(0.5, 0)

    topContainer.add(scoreText);

    const paramContainerX = isMobileDevice() ? parseStyle("xp-4") : parseStyle("xp-2");
    const paramContainerY =
      resultsBanner.getBounds().height / 2 +
      topContainer.getBounds().height +
      (isMobileDevice() ? 0 : parseStyle("yp-6"));

    const paramContainer = this.add.container(paramContainerX, paramContainerY);
    widgetContainer.add(paramContainer);

    let topContainerHeight =
      topContainer.getBounds().height +
      (isMobileDevice() ? 0 : parseStyle("yp-4"))

    let statsContainerY =
      resultsBanner.getBounds().height / 2 + topContainerHeight;

    const statsContainer = this.add.container(parseStyle("xp-3"), statsContainerY);
    widgetContainer.add(statsContainer);

    const parameters = getParameters(this.data, this.stats);

    let progressBarHeight = applyScale(60);
    let parameterX = 0;
    let parameterY = 0;

    parameters.forEach((parameter, index) => {
      if (index % 2 === 0) {
        parameterX = 0;
      } else {
        parameterX = boardWidth / 2;
      }

      if (
        index % 2 === 0 &&
        index !== 0 &&
        isPCDevice()
      ) {
        parameterY += applyScale(150);
      }

      if (isMobileDevice() || isTabletDevice()) {
        parameterX = parseStyle("w-2");
        parameterY += applyScale(150);
      }

      const icon = createImage(this, parameterX, parameterY, parameter.icon, { scale: 1.3 });

      let progressBarX = parameterX + icon.getBounds().width;
      let progressBarY = parameterY - icon.getBounds().height / 2;

      const xOffsetProgressBar = 
        isMobileDevice() ? parseStyle("xp-10") : parseStyle("xp-4")

      let progressBarWidth =
        (isPCDevice() ? boardWidth / 2 : boardWidth) -
        icon.getBounds().width -
        xOffsetProgressBar

      const parameterText = createText(
        this,
        progressBarX,
        progressBarY - parseStyle("yp-1"),
        parameter.name,
        {
          fontSize: TEXT_SIZE_NORMAL,
          color: WHITE_SHADE,
          align: "center",
        }
      )

      paramContainer.add(icon);
      paramContainer.add(parameterText);

      const radius = applyScale(30);

      const progressBarBackground = this.add.graphics();
      progressBarBackground.fillStyle(WHITE_SHADE_0x);
      progressBarBackground.fillRoundedRect(
        progressBarX,
        progressBarY + (parameterText.height * 2) / 3,
        progressBarWidth,
        progressBarHeight,
        radius
      );

      paramContainer.add(progressBarBackground);

      const currentProgress =
        (progressBarWidth * ((parameter.progress / parameter.maxValue) * 100)) /
        100;

      const progressBar = this.add.graphics();

      if (currentProgress > 0) {
        progressBar.fillStyle(ORANGE_SHADE_0x);

        progressBar.fillRoundedRect(
          progressBarX,
          progressBarY + (parameterText.height * 2) / 3,
          currentProgress,
          progressBarHeight,
          radius
        );

        paramContainer.add(progressBar);
      }

      const progressText = this.add
        .text(
          progressBarX + progressBarWidth - parseStyle("w-1"),
          progressBarY + parameterText.height + progressBarHeight / 3,
          parseInt(parameter.progress) + parameter.unit,
          {
            fontSize: TEXT_SIZE_NORMAL,
            color: BLACK_SHADE,
          }
        )
        .setOrigin(1, 0.5)

      paramContainer.add(progressText);
    });

    const buttonsContainer = this.add.container(
      0,
      resultsBanner.getBounds().height / 2 +
      topContainer.getBounds().height +
      paramContainer.getBounds().height +
      (isMobileDevice() ? parseStyle("h-10") : parseStyle("h-12"))
    );

    const continueButton = createImage(
      this,
      boardWidth / 2,
      0,
      ASSET_IMG_BUTTON_CONTINUE
    ).setInteractive();

    continueButton.on("pointerdown", () => {
      this.replayButtonAction(this.gameID);
      this.scene.start(SCENE_LEVEL_DESCRIPTION);
    });

    this.tweens.add({
      targets: continueButton,
      scaleX: applyScale(1.05),
      scaleY: applyScale(1.05),
      duration: 500,
      ease: "Quad.easeIn",
      yoyo: true,
      repeat: -1,
    });

    buttonsContainer.add(continueButton);

    widgetContainer.add(buttonsContainer);

    if (this.gameID === GAME_ID_WORD_SEARCH) {
      const wordsSection = this.add.container(
        parseStyle("w-1"),
        resultsBanner.getBounds().height / 2 +
        topContainer.getBounds().height +
        paramContainer.getBounds().height +
        buttonsContainer.getBounds().height +
        parseStyle("yp-10")
      );

      widgetContainer.add(wordsSection);

      const selectedWordText = createText(
        this, 
        boardWidth / 2 - parseStyle("w-1"), 0, "- Your Words -", {
        fontSize: TEXT_SIZE_NORMAL,
        color: WHITE_SHADE,
        align: "center",
      })
        .setOrigin(0.5, 0);

      wordsSection.add(selectedWordText);

      const wordsSectionBackground = this.add
        .rectangle(
          parseStyle("w-1"),
          selectedWordText.height + parseStyle("h-2"),
          boardWidth - parseStyle("w-4"),
          boardHeight * 0.9 - wordsSection.y,
          DARK_PURPLE_SHADE,
          20
        )
        .setOrigin(0, 0)
        .setDepth(1);

      wordsSection.add(wordsSectionBackground);

      const wordsContainer = document.createElement("div");
      wordsContainer.classList.add("words");
      wordsContainer.setAttribute("id", "words-container");
      wordsContainer.setAttribute(
        "style",
        `color: white; font-size: ${TEXT_SIZE_NORMAL}px Arial; display: flex; max-width: calc(${boardWidth}px - ${parseStyle("w-6")}px)`
      );

      const wordsDOMElement = this.add
        .dom(parseStyle("w-3"), selectedWordText.height + parseStyle("h-3"), wordsContainer)
        .setOrigin(0);

      this.data?.correctWords?.forEach((word) => {
        const p = document.createElement("p");
        p.innerText = word.toUpperCase();
        p.setAttribute("style", `font-size: ${TEXT_SIZE_NORMAL}px; margin-right: ${applyScale(4)}rem`)
        wordsContainer.append(p);
      });

      wordsSection.add(wordsDOMElement);
    }
  }

  animateGameResultContainer(target, startX, startY, boardHeight, boardWidth) {
    target.setPostPipeline(WIPE_POST_FX);
    const pipeline = target.getPostPipeline(WIPE_POST_FX);
    pipeline.setTopToBottom();
    pipeline.setRevealEffect();

    this.tweens.add({
      targets: pipeline,
      progress: 1,
      duration: 500,
      onComplete: () => {
        this.renderScoreResult(startX, startY, boardHeight, boardWidth);
      },
    });
  }

  calculateDimensions() {
    let startX = isMobileDevice() ? parseStyle("w-5") : parseStyle("w-25");
    let startY = isMobileDevice() ? parseStyle("h-15") : parseStyle("h-10");

    let boardWidth = parseStyle("w-100") - 2 * startX;
    let boardHeight = parseStyle("h-100") - 2 * startY;

    return { startX, startY, boardWidth, boardHeight };
  }

  getSymbolsAccuracy() {
    return (
      (
        this.data?.correctSymbols?.length / this.data?.totalSymbols?.length
      ).toFixed(2) * 100
    );
  }

  getWordsAccuracy() {
    const totalWordsCount = this.data.totalWords.length;
    return (this.data.correctWords.length / totalWordsCount).toFixed(2) * 100;
  }

  create() {
    const fillColor = PURPLE_SHADE;
    const { startX, startY, boardWidth, boardHeight } =
      this.calculateDimensions();

    renderControlButtons(this, {
      isSettingsButton: false,
      isInfoButton: false
    });

    const graphics = createWidgetBackground(
      this,
      boardWidth,
      boardHeight,
      startX,
      startY,
      fillColor
    );

    this.animateGameResultContainer(
      graphics,
      startX,
      startY,
      boardHeight,
      boardWidth
    );
  }
}
