export const injectStyles = (props) => {
  const style = document.createElement('style');
  const { width, padX, textSize } = props

  style.innerHTML = `
    .instructions-container {
      font-size: ${textSize}px;
      line-height: 1.6;
      width: 100%;
      max-width: ${width - 2 * padX}px;
      margin: 0 auto;
      padding: 20px;
      box-sizing: border-box;
      overflow-y: auto;
    }
    
    .instructions-container ul {
      padding-left: 10px;
      margin-top: 0;
      margin-bottom: 20px;
      list-style-type: circle;
    }
    
    .instructions-container li {
      margin-bottom: 10px;
    }
    
    .instructions-container p {
      margin-bottom: 20px;
    }
  `;
  document.head.appendChild(style);
}