import { autorun } from "mobx";
import {
  ASSET_IMG_ICON_SOUND_OFF,
  ASSET_IMG_ICON_SOUND_ON,
} from "../../../utils/constants/assetkeys";
import { settingStore } from "../../_stores/SettingsStore";
import { isMobileDevice, isTabletDevice } from "../../_utils/deviceType";
import { setCursorToDefault, setCursorToPointer } from "../../GridProblems/_utils/cursorUtils";
import { getControlButtonPositions } from "../../_utils/getButtonPositions";
import { createImage } from "../../_utils/styleUtilities/_scaling";

export class MuteButton {
  constructor(scene, xPos, yPos) {
    this.scene = scene;
    this.xPos = xPos;
    this.yPos = yPos;

    return this.createButton();
  }

  createButton() {
    const { scene, xPos, yPos } = this;

    let soundButton = createImage(scene , xPos, yPos, ASSET_IMG_ICON_SOUND_OFF)
      .setDepth(2);

    autorun(() => {
      if (settingStore.muted === true) {
        soundButton.setTexture(ASSET_IMG_ICON_SOUND_OFF);
        scene.sound.mute = true;
      } else {
        soundButton.setTexture(ASSET_IMG_ICON_SOUND_ON);
        scene.sound.mute = false;
      }
    });

    soundButton.setInteractive();

    const { settingsButtonY, mobileOffsetX } = getControlButtonPositions()

    if (isMobileDevice() || isTabletDevice()) {
      soundButton.setPosition(
        xPos - mobileOffsetX,
        settingsButtonY
      );
    }

    soundButton.on("pointerover", () => {
      soundButton.setAlpha(0.7);
      setCursorToPointer(scene);
    });

    soundButton.on("pointerout", () => {
      soundButton.setAlpha(1);
      setCursorToDefault(scene);
    });

    soundButton.on("pointerdown", () => {
      scene.sound.mute = !settingStore.muted;
      settingStore.setMute(!settingStore.muted);

      setCursorToDefault(scene);
    });

    return soundButton;
  }
}
