import { autorun } from "mobx";
import { settingStore } from "../../_stores/SettingsStore";

export function initializeSliderLogic(sliderName) {
  let value = null 
  
  switch (sliderName) {
    case 'sound-slider':
      autorun(() => {
        value = settingStore.soundVolume
      })
      break;
    case 'music-slider':
      autorun(() => {
        value = settingStore.musicVolume
      })
      break;
    default:
      break;
  }

  function updateValue(newValue) {
    switch (sliderName) {
      case "sound-slider":
        settingStore.setSoundVolume(newValue)
        break;
      case "music-slider":
        settingStore.setMusicVolume(newValue)
        break;
      default:
        break;
    }
  }

  function getValue() {
    return value;
  }

  return {
    updateValue,
    getValue
  };
}
