import { axiosClient } from "../axiosClient";
import { exerciseApi } from "./api";
import { showErrorToast } from "../../components/toasts";

export const getExercises = async (course_id) => {
  try {
    const response = await axiosClient.get(exerciseApi.list(course_id));
    return response.data;
  } catch (error) {
    showErrorToast(error.message);
  }
};

export const getExerciseDetails = async (course_id, exercise_id) => {
  try {
    const response = await axiosClient.get(
      exerciseApi.details(course_id, exercise_id)
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.message);
  }
};

export const createExerciseAttempt = async (course_id, payload) => {
  try {
    const response = await axiosClient.post(
      exerciseApi.createAttempt(course_id),
      payload
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};
