import { autorun } from "mobx";
import { settingStore } from "../../_stores/SettingsStore";
import { infoButtonAction } from "../../GridProblems/_utils/settingsButtonHelper";
import { CloseButton } from "../../GridProblems/_components/buttons/CloseButton";
import { ASSET_IMG_GAME_PLAY, ASSET_IMG_ICON_INFO } from "../../../utils/constants/assetkeys";
import { createWidgetBackground } from "../../GridProblems/_components/WidgetBackground";
import { getBackdrop } from "../../GridProblems/_components/Backdrop";
import { isMobileDevice, isTabletDevice } from "../../_utils/deviceType";
import { setCursorToDefault, setCursorToPointer } from "../../GridProblems/_utils/cursorUtils";
import { TEXT_SIZE_MEDIUM } from "../../../utils/constants";
import { createImage } from "../../_utils/styleUtilities/_scaling";
import { getControlButtonPositions } from "../../_utils/getButtonPositions";
import { parseStyle } from "../../_utils/styleUtilities/_position";
import { getInstructions } from "../../../services/instructions/service";
import { userDataStore } from "../../../stores/userDataStore";
import { injectStyles } from "../../_utils/instructionsStyle";

export class InfoButton {
  constructor(scene, xPos, yPos) {
    this.scene = scene;
    this.xPos = xPos;
    this.yPos = yPos;

    const { width, padX } = this.initializePropeties();
    injectStyles({ width, padX, textSize: TEXT_SIZE_MEDIUM});

    this.fetchInstructions();
  }

  async fetchInstructions() {
    const { scene } = this;
    await getInstructions(userDataStore.currentExercise.id).then((res) => {
      this.instructionsHTML = res[0]?.description;
      this.infoButton = this.createButton();
      this.createWidget();

      this.infoButton.on("pointerdown", () => {
        settingStore.setInfoModalOpen(!settingStore.infoModalOpen);
        setCursorToDefault(scene);
        this.infoButton.setAlpha(1);
      });
    });
  }

  createButton() {
    const { scene, xPos, yPos } = this
    const btn = createImage(scene, xPos, yPos, ASSET_IMG_ICON_INFO)
      .setDepth(2);

    const { settingsButtonY, mobileOffsetX } = getControlButtonPositions()

    if (isMobileDevice() || isTabletDevice()) {
      btn.setPosition(
        xPos + mobileOffsetX,
        settingsButtonY
      );
    }

    btn.setInteractive();

    btn.on("pointerover", () => {
      btn.setAlpha(0.7);
      setCursorToPointer(scene);
    });

    btn.on("pointerout", () => {
      btn.setAlpha(1);
      setCursorToDefault(scene);
    });

    return btn;
  }

  createWidget() {
    const { scene } = this;
    const { startX, startY, width, height, padX, padY } = this.initializePropeties();

    const backdrop = getBackdrop(scene);
    backdrop.visible = false;

    const widgetContainer = scene.add.container(startX, startY).setDepth(11);
    const background = createWidgetBackground(scene, width, height, 0, 0);

    const gamePlayText =
      createImage(scene, padX, padY, ASSET_IMG_GAME_PLAY, {
        scale_mb: 0.6, scale_tb: 0.8, scale_pc: 0.8
      })
        .setOrigin(0);

    const closeButton = new CloseButton(scene, width, 0, infoButtonAction);

    const availableWidth = width - 4 * padX;

    const textElement = scene.add
      .dom(
        padX,
        gamePlayText.getBounds().height + 1.5 * padY,
        this.createDescriptionText(availableWidth)
      )
      .setOrigin(0);

    if (background) widgetContainer.add(background);
    widgetContainer.add(textElement);
    widgetContainer.add(gamePlayText);
    widgetContainer.add(closeButton);
    widgetContainer.setScale(0);

    autorun(() => {
      if (settingStore.infoModalOpen) {
        backdrop.visible = true;
        scene.tweens.add({
          targets: widgetContainer,
          scaleX: 1,
          scaleY: 1,
          x: widgetContainer.x,
          y: widgetContainer.y,
          duration: 500,
          ease: "Back.Out",
        });
      } else {
        backdrop.visible = false;
        scene.tweens.add({
          targets: widgetContainer,
          scaleX: 0,
          scaleY: 0,
          duration: 500,
          ease: "Back.In",
        });
      }
    });
  }

  initializePropeties() {
    let startX = isMobileDevice() ? parseStyle("w-5") : parseStyle("w-15");
    let startY = isMobileDevice() ? parseStyle("h-10") : parseStyle("h-15");

    const width = parseStyle("w-100") - 2 * startX;
    const height = parseStyle("h-100") - 2 * startY;

    const padX = width * 0.05;
    const padY = height * 0.05;

    return {
      startX,
      startY,
      height,
      width,
      padX,
      padY,
    };
  }

  createDescriptionText() {
    const textDOMContainer = document.createElement("div");
    textDOMContainer.setAttribute("class", "instructions-container");

    textDOMContainer.innerHTML = this.instructionsHTML 
      ? this.instructionsHTML 
      : "<p>No instructions available.</p>";

    return textDOMContainer;
  }
}
