import {
  GAME_ID_ALPHABET_SEARCH,
  GAME_ID_NUMBER_SEARCH,
  GAME_ID_SPOT_THE_DIFFERENCE,
  GAME_ID_WORD_SEARCH,
} from "@rewire-brain/grid-engine/utils/constants";

import { routes } from '.'
import { GAME_ID_ARRANGE_THE_NUMBERS, GAME_ID_FIND_THE_DIFFERENT } from "../utils/constants";

export const gameRoutes = [
  { path: routes.getWordSearchRoute(), game: GAME_ID_WORD_SEARCH },
  { path: routes.getAlphabetSearchRoute(), game: GAME_ID_ALPHABET_SEARCH },
  { path: routes.getNumberSearchRoute(), game: GAME_ID_NUMBER_SEARCH },
  { path: routes.getSpotTheDifferenceRoute(), game: GAME_ID_SPOT_THE_DIFFERENCE },
  { path: routes.getArrangeTheNumbersRoute(), game: GAME_ID_ARRANGE_THE_NUMBERS },
  { path: routes.getFindTheDifferentRoute(), game: GAME_ID_FIND_THE_DIFFERENT },
];