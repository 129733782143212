import { MOBILE, PC, TABLET } from "../../utils/constants";
import { sizeConstantsStore } from "../_stores/SizeConstantsStore"

export const isMobileDevice = () => {
  return sizeConstantsStore.device === MOBILE;
}

export const isPCDevice = () => {
  return sizeConstantsStore.device === PC;
}

export const isTabletDevice = () => {
  return sizeConstantsStore.device === TABLET;
}