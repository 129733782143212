import { sizeConstantsStore } from "../../_stores/SizeConstantsStore";
import {
  BLUE_SHADE,
  LIGHT_PURPLE_SHADE,
  MOBILE,
  TEXT_SIZE_MEDIUM,
  TEXT_SIZE_NORMAL,
} from "../../../utils/constants";
import { applyScale } from "../../_utils/styleUtilities/_scaling";

export class ShadowTextStyle {
  constructor(scene, xPos, yPos, text) {
    this.scene = scene;
    this.xPos = xPos;
    this.yPos = yPos;
    this.text = text;
    this.shadowOffset = applyScale(2);

    return this.createTextElement();
  }

  createTextElement() {
    const { scene, xPos, yPos, text, shadowOffset } = this;

    const container = scene.add.container(xPos, yPos);

    let fontSize = TEXT_SIZE_NORMAL;

    if (sizeConstantsStore.device === MOBILE) fontSize = TEXT_SIZE_MEDIUM;

    const textElementShadow = scene.add
      .text(0 + shadowOffset, 0 + shadowOffset, text, {
        color: LIGHT_PURPLE_SHADE,
        align: "center",
        fontSize: fontSize,
        fontFamily: "Arial",
        fontWeight: "bold",
      })
      .setDepth(12);

    const textElement = scene.add
      .text(0, 0, text, {
        color: BLUE_SHADE,
        align: "center",
        fontSize: fontSize,
        fontFamily: "Arial",
        fontWeight: 600,
      })
      .setDepth(13);

    container.add(textElementShadow);
    container.add(textElement);

    return container;
  }
}
