import React from 'react';
import ListComponent from './_ListComponent';
import { company, employers, candidates, community } from "./_footerLinks";

const FooterTop = () => {
  return (
    <div className="bg-grayShade1 py-3 px-5 flex justify-center text-grayShade4">
      <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-3 w-[100%] max-w-[1000px] pl-5 justify-center">
        <ListComponent list={company} title="Company" />
        <ListComponent list={employers} title="Employers" />
        <ListComponent list={candidates} title="Candidates" />
        <ListComponent list={community} title="Community" />
      </div>
    </div>
  );
};

export default FooterTop;