import {
  MOVE_STATUS_CORRECT,
  MOVE_STATUS_INCORRECT,
} from "@rewire-brain/grid-engine/utils/constants";

export const calculateResults = (stats, isWordGame = false) => {
  const { moves, timeTaken, totalTime } = stats;

  let correctMovesCount = 0;
  let incorrectMovesCount = 0;
  let sameMovesCount = 0;

  let score = 0;
  let maxScore = 0;

  moves.forEach((move) => {
    const { word, status } = move;

    if (status === MOVE_STATUS_CORRECT) {
      correctMovesCount++;
      const moveScore = word ? word.length * 10 : 10;
      score += moveScore;
      maxScore += moveScore;
    } else if (status === MOVE_STATUS_INCORRECT) {
      incorrectMovesCount++;
      const moveScore = word ? word.length * 5 : 5;
      score -= moveScore;
    } else {
      sameMovesCount++;
      const moveScore = word ? word.length * 2 : 2;
      score -= moveScore;
    }
  });

  const calculateScore = () => {
    return ((score < 0 ? 0 : score) / maxScore) * 100;
  };

  const calculateAccuracy = () => {
    if (moves.length === 0 || correctMovesCount === 0) return 0;
    return getCorrectMovesRatio() * 100;
  };

  const getTimeUtilization = () => {
    return getTimeQuotient() * 100;
  };

  const getTimeQuotient = () => {
    return (timeTaken / totalTime) > 1 ? 1 : (timeTaken / totalTime);
  }

  const getCorrectMovesRatio = () => {
    return (correctMovesCount / (correctMovesCount + incorrectMovesCount))
  }

  const calculateEfficiency = () => {
    return (
      (getCorrectMovesRatio() * ((1 - getTimeQuotient()) === 0 ? 0.1 : 1 - getTimeQuotient())) * 100
    );
};

  return {
    accuracy: calculateAccuracy() ? calculateAccuracy() : 0,
    score: calculateScore() ? calculateScore() : 0,
    time: getTimeUtilization(),
    efficiency: calculateEfficiency() ? calculateEfficiency() : 0,
    correctMovesCount,
    incorrectMovesCount,
    sameMovesCount,
  };
};
