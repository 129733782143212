import { MULTIPLIER_HEIGHT, MULTIPLIER_WIDTH } from "../../../../_utils/constants";
import { isMobileDevice } from "../../../../_utils/deviceType";

export const getDimensions = (scene, grid) => {
  const rows = grid.length;
  const cols = grid[0].length;
  let gameWidth = scene.scale.width;
  let gameHeight = scene.scale.height;

  let tileSize = Math.min(
    (gameWidth * MULTIPLIER_WIDTH - scene.tileGap * (cols - 1)) / cols,
    (gameHeight * (isMobileDevice() ? MULTIPLIER_WIDTH : MULTIPLIER_HEIGHT) - scene.tileGap * (rows - 1)) / rows
  )

  const totalWidth = cols * tileSize;
  const totalHeight = rows * tileSize;

  const startX = (gameWidth - totalWidth) / 2 + tileSize / 2;
  const startY = (gameHeight - totalHeight) / 2 + tileSize / 2;

  return { rows, cols, startX, startY, tileSize };
};