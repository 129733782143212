import Phaser from "phaser";
import { initializeSliderLogic } from "../_utils/sliderHelper";
import { sizeConstantsStore } from "../../_stores/SizeConstantsStore";
import {
  BLACK_SHADE,
  MOBILE,
  SLIDER_COLOR_DARK,
  SLIDER_COLOR_LIGHT,
  TEXT_SIZE_NORMAL,
} from "../../../utils/constants";
import { setCursorToDefault, setCursorToPointer } from "../_utils/cursorUtils";
import { applyScale } from "../../_utils/styleUtilities/_scaling";
import { isMobileDevice } from "../../_utils/deviceType";

export default class Slider {
  constructor(scene, xPos, yPos, width, sliderName) {
    this.scene = scene;
    this.xPos = xPos;
    this.yPos = yPos;
    this.width = width;
    this.sliderName = sliderName;
    this.logic = initializeSliderLogic(sliderName);
    this.value = this.logic.getValue();

    this.sliderHeight = applyScale(50)

    this.thumbWidth = applyScale(20)
    this.thumbHeight = applyScale(70)

    this.sliderYOffset = - this.sliderHeight / 2

    this.xOffset = applyScale(100)
    this.yOffset = applyScale(15)

    this.sliderTextOffset = applyScale(70);

    return this.createSlider();
  }

  createSlider() {
    const { scene, xPos, yPos, width } = this;

    const sliderX = isMobileDevice() ? xPos : xPos - this.xOffset
    const sliderY = isMobileDevice() ? yPos + 2 * this.sliderHeight : yPos + this.yOffset
    const sliderContainer = scene.add.container(sliderX, sliderY);

    const sliderText = scene.add.text(width + this.sliderTextOffset, 0, "", {
      color: BLACK_SHADE,
      fontSize: TEXT_SIZE_NORMAL
    }).setOrigin(0.5)

    const graphics1 = scene.add.graphics();
    graphics1.fillStyle(SLIDER_COLOR_LIGHT, 1);

    const track = graphics1
      .fillRoundedRect(0, this.sliderYOffset, width, this.sliderHeight, 5)
      .setDepth(12);
    
    const thumb = scene.add
      .rectangle(0, 0, this.thumbWidth, this.thumbHeight, SLIDER_COLOR_DARK).setOrigin(0.5)
      .setDepth(13);

    thumb.setInteractive({ draggable: true });
    scene.input.setDraggable(thumb);

    thumb.on("pointerover", () => {
      setCursorToPointer(scene);
    });

    thumb.on("pointerout", () => {
      setCursorToDefault(scene);
    });

    const slider = scene.add.container(0, 0, [track, thumb]);

    const updateThumbPosition = (newValue) => {
      const thumbX = newValue * (width - thumb.displayWidth);
      thumb.x = thumbX;
      sliderText.text = parseInt(newValue * 100) + " %";
    };

    updateThumbPosition(this.value);

    thumb.on("drag", (pointer, dragX) => {
      const newValue = Phaser.Math.Clamp(
        dragX / (width - thumb.displayWidth),
        0,
        1
      );

      this.logic.updateValue(newValue);
      updateThumbPosition(newValue);
    });

    sliderContainer.add(slider);

    if (sizeConstantsStore.device !== MOBILE) sliderContainer.add(sliderText);

    return sliderContainer;
  }

  getValue() {
    return this.value;
  }
}
