import { action, makeAutoObservable, observable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class AuthStore {
  authState = false
  user = {
    name: "",
    candidateId: ""
  }
  
  constructor() {
    makeAutoObservable(this, {
      authState: observable,
      setAuth: action
    });

    makePersistable(this, {
      name: "AuthStore",
      properties: ["authState"],
      storage: window.localStorage,
    });
  }

  setAuth(state) {
    this.authState = state
  }
}

export const authStore = new AuthStore()