import React, { useEffect, useState } from "react";
import Container from "../components/Layout/Container";
import TabBar from "../components/TabBar";
import CourseCard from "../components/CourseCard";
import { Helmet } from "react-helmet";
import { getCourses } from "../services/courses/service";

const Courses = () => {
  const [tabState, setTabState] = useState("courses");
  const [courses, setCourses] = useState([])

  const tabMenu = [
    { name: "Learn", slug: "courses" },
  ];

  const handleFetchCourses = async () => {
    const response = await getCourses();
    setCourses(response);
  }

  useEffect(() => {
    handleFetchCourses()
  }, [])

  return (
    <div>
      <Helmet>
        <title>Courses | SCALE</title>
        <meta name="description" content="Start with some basic courses containing  games and activities to level up your activities." />
      </Helmet>
      <div className="bg-blue-shade text-white">
        <Container>
          <p className="text-3xl font-semibold uppercase my-10 tracking-wider">Courses</p>
          <TabBar
            tabMenu={tabMenu}
            setTabState={setTabState}
            tabState={tabState}
          />
        </Container>
      </div>

      <Container>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-5 mt-5">
          {tabState === 'courses' ? courses?.map((course) => (
            <CourseCard course={course} />
          )) : <></>}
        </div>
      </Container>
    </div>
  );
};

export default Courses
