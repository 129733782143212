import { GRAY_SHADE, WHITE_SHADE, WHITE_SHADE_0x } from "../../utils/constants";

const createLoadingText = (scene, width, height) => {
  const loadingText = scene.make.text({
    x: width / 2,
    y: height / 2 - 50,
    text: 'Loading...',
    style: {
      font: '20px monospace',
      fill: WHITE_SHADE
    }
  });

  loadingText.setOrigin(0.5, 0.5);
  return loadingText;
}

export const createLoadingScreen = (scene) => {
  const progressBar = scene.add.graphics();
  const progressBox = scene.add.graphics();
  progressBox.fillStyle(GRAY_SHADE, 0.8);

  const { width, height } = scene.cameras.main;
  progressBox.fillRect(width / 2 - 160, height / 2 - 30, 320, 50);

  const loadingText = createLoadingText(scene, width, height);

  const percentText = scene.make.text({
    x: width / 2,
    y: height / 2 - 5,
    text: '0%',
    style: {
      font: '18px monospace',
      fill: WHITE_SHADE
    }
  });
  percentText.setOrigin(0.5, 0.5);

  scene.load.on('progress', (value) => {
    percentText.setText(parseInt(value * 100) + '%');
    progressBar.clear();
    progressBar.fillStyle(WHITE_SHADE_0x, 1);
    progressBar.fillRect((width / 2) - 150, (height / 2) - 20, 300 * value, 30);
  });

  scene.load.on('complete', () => {
    progressBar.destroy();
    progressBox.destroy();
    loadingText.destroy();
    percentText.destroy();
  });
}