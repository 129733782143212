import { BLACK_SHADE } from "../../utils/constants";
import { ASSET_IMG_SCORE_BADGE } from "../../utils/constants/assetkeys";
import { isPCDevice } from "../_utils/deviceType";
import { getControlButtonPositions } from "../_utils/getButtonPositions";
import { parseStyle } from "../_utils/styleUtilities/_position";
import { createImage, createText } from "../_utils/styleUtilities/_scaling";

export const renderScore = (scene) => {
  const { settingsButtonX, settingsButtonY } = getControlButtonPositions()
  let offset = {
    y: isPCDevice() ? 0 : parseStyle("h-10"),
    x: isPCDevice() ? parseStyle("w-25") : settingsButtonX
  }

  const scoreX = 0 + offset.x
  const scoreY = settingsButtonY + offset.y;

  const scoreBadge = createImage(scene, scoreX, scoreY, ASSET_IMG_SCORE_BADGE)
    .setOrigin(0, 0.5)

  scene.scoreText = createText(scene, scoreX + (scoreBadge.getBounds().width / 2), scoreY, scene.score, {
    fontSize: "42px",
    fill: BLACK_SHADE,
  })
    .setOrigin(0, 0.5);
};
