import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import EnvironmentBadge from "../EnvironmentBadge";

const fullPageRoutes = [
  "/puzzle",
  "/word-search",
  "/alphabet-search",
  "/number-search",
  "/spot-the-difference",
  "/arrange-the-numbers",
  "/find-the-different",
];

const Layout = ({ children }) => {
  const location = useLocation();
  const isFullPage = useMemo(() => fullPageRoutes.includes(location.pathname), [location.pathname]);

  return (
    <div className="h-full relative">
      <EnvironmentBadge />
      <div className="w-full">
        {!isFullPage && <Header />}
        <main className="min-h-[90vh]">{children}</main>
        {!isFullPage && <Footer />}
      </div>
    </div>
  );
};

export default Layout;
