import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import PopOver from "../../CustomUI/PopOver";
import { FaUser } from "react-icons/fa";
import { GrLogout } from "react-icons/gr";
import { MdArrowDropDown } from "react-icons/md";
import { authStore } from "../../../stores/authStore";
import { logout } from "../../../services/auth/service";
import { URL_ELITMUS_CONTACT_US } from "../../../utils/constants/elitmusURLs";

const AccountSettings = () => {
  const { user } = authStore;

  return (
    <PopOver
      targetElement={
        <UserAvatar user={user} />
      }
    >
      <AccountDropDown />
    </PopOver>
  );
};

const UserAvatar = ({ user }) => (
  <div className="flex justify-center items-center p-3 rounded-full text-white cursor-pointer">
    <p data-testid="user-id" className="flex items-center">
      {user.name}(<FaUser className="mx-1" />{user.candidateId}) <MdArrowDropDown className="text-2xl ml-1" />
    </p>
  </div>
);

const AccountDropDown = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout(() => {
      navigate("/");
    });
  };

  return (
    <div
      data-testid="avatar-dropdown"
      className="absolute top-[60px] border right-[10px] bg-white p-2 rounded-md shadow-lg"
    >
      <div className="w-[200px]">
        <Link to={URL_ELITMUS_CONTACT_US}>
          <DropdownItem label="Contact Us" />
        </Link>
        <div className="h-px bg-gray-100" />
        <div
          onClick={handleLogout}
          className="flex items-center px-6 py-2 cursor-pointer text-red-500"
        >
          <GrLogout />
          <p className="ml-5">Logout</p>
        </div>
      </div>
    </div>
  );
};

const DropdownItem = ({ label }) => (
  <p className="ml-5 px-6 py-2 cursor-pointer">{label}</p>
);

export default AccountSettings;
