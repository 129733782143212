import { makeAutoObservable, observable } from "mobx";
import { PC } from "../../utils/constants";

class SizeConstantsStore {
  device = PC

  constructor() {
    makeAutoObservable(this, {
      device: observable,
    })
  }
}

export const sizeConstantsStore = new SizeConstantsStore();