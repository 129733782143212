import { routes } from "../../../routes";
import { URL_ELITMUS, URL_ELITMUS_JOBS, URL_ELITMUS_TESTS } from "../../../utils/constants/elitmusURLs";

export const menuItemsLoggedIn = [
  {
    title: 'Home',
    path: routes.getCoursesRoute(),
  },
  {
    title: 'About',
    path: routes.getHomeRoute(),
  },
  {
    title: 'Tests',
    path: URL_ELITMUS_TESTS,
  },
  {
    title: 'Jobs',
    path: URL_ELITMUS_JOBS,
  }
];

export const menuItemsPublic = [
  {
    title: 'eLitmus',
    path: URL_ELITMUS
  },
  {
    title: 'Register',
    path: routes.getSSOUrl(),
  },
  {
    title: 'Login',
    path: routes.getSSOUrl(),
  },
];