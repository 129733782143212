import { instructionsApi } from "./api";
import { axiosClient } from "../axiosClient"
import { showErrorToast } from "../../components/toasts";

export const getInstructions = async (exercise_id) => {
  try {
    const response = await axiosClient.get(instructionsApi.details(exercise_id))
    return response.data
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
}