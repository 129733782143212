import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "../components/Layout/Container";
import HeadingLarge from "../components/CustomUI/HeadingLarge";

import { TbInfoOctagonFilled } from "react-icons/tb";
import { IoTime } from "react-icons/io5";

import ExerciseCard from "../components/ExerciseCard";
import BackButton from "../components/BackButton";
import { Helmet } from "react-helmet";
import { formatTime } from "../utils/formatTime"
import { getCourseDetails } from "../services/courses/service";
import moment from "moment";
import { getExercises } from "../services/exercises/service";
import { userDataStore } from "../stores/userDataStore";

const CoursePage = () => {
  const { id } = useParams();
  const [course, setCourse] = useState({});
  const [exercisesList, setExercisesList] = useState([]);
  const navigate = useNavigate();

  const handleFetchExercises = async () => {
    const response = await getExercises(id)
    setExercisesList(response)
  }

  const handleFetchCourseDetails = async () => {
    const response = await getCourseDetails(id);
    setCourse(response);
  }

  useEffect(() => {
    handleFetchCourseDetails();
  }, [id])

  const handleBack = () => {
    navigate("/courses");
  }
  useEffect(() => {
    handleFetchExercises();
    userDataStore.setCurrentCourse(course);
  }, [course])

  return (
    <div>
      <Helmet>
        <title>{userDataStore?.currentCourse?.name || "Loading..."} | SCALE</title>
        <meta name="description" content="Leadership and followership are mindsets. They’re just different ways of looking at the world. One is reactive, and the other is proactive. Where one sees a to-do list, the other sees possibilities." />
      </Helmet>
      <div className="bg-blue-shade text-white py-10">
        <Container>
          <div>
            <BackButton onClick={handleBack} />
          </div>
          <div className="relative grid md:grid-cols-2 gap-5">
            <div>
              <HeadingLarge title={course.name} />

              <div className="mt-3 text-sm">
                <p className="flex items-center mb-4">
                  <IoTime className="text-2xl mr-1" />
                  {formatTime(course.estimated_time)} min
                </p>

                <div className="flex">
                  <p className="flex items-center">
                    <TbInfoOctagonFilled className="text-xl mr-2" /> Last
                    Updated {moment(course.updated_at).format('MMMM D, YYYY')}
                  </p>
                  <p className="flex items-center ml-5">
                    <TbInfoOctagonFilled className="text-xl mr-2" /> Last
                    Subscribed at {moment(course.subscribed_at).format('MMMM D, YYYY')}
                  </p>
                </div>
              </div>
            </div>
            <div className="relative w-[100%]">
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className="grid grid-cols-1 my-5">
          <div>
            <h2 className="font-semibold text-2xl mb-2">Description</h2>
            <p>
              {course.description}
            </p>
          </div>
          <div></div>
        </div>
      </Container>

      <div>
        <Container>
          <div className="grid grid-cols-1 my-5">
            <div>
              <h2 className="font-semibold text-2xl mb-3">Exercises</h2>

              <div className="grid md:grid-cols-3 lg:grid-cols-5 grid-cols-1 gap-5">
                {exercisesList?.map((exercise) => (
                  <ExerciseCard exercise={exercise} />
                ))}
              </div>
            </div>
            <div></div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default CoursePage;
