import {
  GAME_ID_ALPHABET_SEARCH,
  GAME_ID_NUMBER_SEARCH,
  GAME_ID_SPOT_THE_DIFFERENCE,
  GAME_ID_WORD_SEARCH,
} from "@rewire-brain/grid-engine/utils/constants";

import {
  GAME_ID_ARRANGE_THE_NUMBERS,
  GAME_ID_FIND_THE_DIFFERENT,
} from "../../../utils/constants"

export const getScoreString = (data, stats, gameID) => {
  let scoreString = ""
  let totalglyphs = 0
  let correctglyphs = 0

  switch (gameID) {
    case GAME_ID_WORD_SEARCH:
      totalglyphs = data?.totalWords?.length || 0;
      correctglyphs = data?.correctWords?.length || 0;
      break;
    case GAME_ID_ARRANGE_THE_NUMBERS:
      totalglyphs = data.additionalArray.length;
      correctglyphs = stats.correctMovesCount;
      break;
    case GAME_ID_FIND_THE_DIFFERENT:
      totalglyphs = data.totalCorrect;
      correctglyphs = stats.correctMovesCount;
      break;
    default:
      totalglyphs = data?.totalSymbols?.length || 0;
      correctglyphs = data?.correctSymbols?.length || 0;
      break;
  }

  switch (gameID) {
    case GAME_ID_WORD_SEARCH:
      scoreString = `${correctglyphs} / ${totalglyphs} words found`;
      break;
    case GAME_ID_ALPHABET_SEARCH:
    case GAME_ID_SPOT_THE_DIFFERENCE:
      scoreString = `${correctglyphs} / ${totalglyphs} alphabets found`;
      break;
    case GAME_ID_NUMBER_SEARCH:
      scoreString = `${correctglyphs} / ${totalglyphs} numbers found`;
      break;
    default:
      scoreString = `${correctglyphs} / ${totalglyphs} glyphs found`;
      break;
  }

  return scoreString
}