import { autorun } from "mobx";

import Slider from "../../GridProblems/_components/Slider";
import { CloseButton } from "../../GridProblems/_components/buttons/CloseButton";
import { ShadowTextStyle } from "../../GridProblems/_components/ShadowTextStyle";

import { settingStore } from "../../_stores/SettingsStore";

import { settingsButtonAction } from "../../GridProblems/_utils/settingsButtonHelper";
import {
  getSliderPositionProperties,
} from "../../GridProblems/_utils/sizeCalculations";

import {
  ASSET_IMG_DARK_TILE,
  ASSET_IMG_ICON_SETTINGS,
  ASSET_IMG_ORANGE_BANNER,
  ASSET_IMG_WHITE_TILE,
} from "../../../utils/constants/assetkeys";
import { getBackdrop } from "../../GridProblems/_components/Backdrop";
import { createWidgetBackground } from "../../GridProblems/_components/WidgetBackground";
import { isMobileDevice } from "../../_utils/deviceType";
import { setCursorToDefault, setCursorToPointer } from "../../GridProblems/_utils/cursorUtils";
import { createImage } from "../../_utils/styleUtilities/_scaling";
import { parseStyle } from "../../_utils/styleUtilities/_position";

export class SettingsButton {
  constructor(scene, xPos, yPos) {
    this.scene = scene;
    this.xPos = xPos;
    this.yPos = yPos;

    this.settingsBtn = this.createButton();

    this.createWidget();

    this.settingsBtn.on("pointerdown", () => {
      settingStore.setSettingsOpen(!settingStore.settingsOpen);
      setCursorToDefault(scene);
      this.settingsBtn.setAlpha(1);
    });
  }

  createButton() {
    const { scene, xPos, yPos } = this;
    const btn = createImage(scene, xPos, yPos, ASSET_IMG_ICON_SETTINGS)
      .setDepth(2)

    btn.setInteractive();

    btn.on("pointerover", () => {
      btn.setAlpha(0.7);
      setCursorToPointer(scene);
    });

    btn.on("pointerout", () => {
      btn.setAlpha(1);
      setCursorToDefault(scene);
    });

    return btn;
  }

  createWidget() {
    const { scene } = this;

    let startX = isMobileDevice() ? parseStyle("w-5") : parseStyle("w-25");
    let startY = isMobileDevice() ? parseStyle("h-10") : parseStyle("h-25");

    const widgetW = parseStyle("w-100") - 2 * startX;
    const widgetH = parseStyle("h-100") - 2 * startY;

    const backdrop = getBackdrop(scene);
    backdrop.visible = false;

    const widgetContainer = scene.add.container(startX, startY).setDepth(11);

    const background = createWidgetBackground(scene, widgetW, widgetH, 0, 0);

    const banner =
      createImage(scene, widgetW / 2, -10, ASSET_IMG_ORANGE_BANNER, {
        scale_pc: 1.2, scale_tb: 1.2, scale_mb: 1
      })

    const closeButton = new CloseButton(
      scene,
      widgetW,
      0,
      settingsButtonAction
    );

    const { sliderX, sliderY, sliderWidth } = getSliderPositionProperties(widgetW, widgetH, parseStyle("w-10"));

    const soundContainer = scene.add.container(
      parseStyle("w-5"),
      !isMobileDevice() ? widgetH * 0.2 : widgetH * 0.1
    );
    const soundText = new ShadowTextStyle(scene, 0, 10, "Sound FX");

    let soundSlider = null;
    soundSlider = new Slider(
      scene,
      sliderX,
      sliderY,
      sliderWidth,
      "sound-slider"
    );

    soundContainer.add(soundText);
    soundContainer.add(soundSlider);

    const musicContainer = scene.add.container(
      parseStyle("w-5"),
      soundContainer.getBounds().y +
      soundContainer.getBounds().height +
      widgetH * 0.1
    );
    const musicText = new ShadowTextStyle(scene, 0, 10, "Music");

    let musicSlider = null;
    musicSlider = new Slider(
      scene,
      sliderX,
      sliderY,
      sliderWidth,
      "music-slider"
    );

    musicContainer.add(musicText);
    musicContainer.add(musicSlider);

    const themeContainer = scene.add.container(
      parseStyle("w-5"),
      musicContainer.getBounds().y +
      musicContainer.getBounds().height +
      widgetH * 0.1
    );
    const themeText = new ShadowTextStyle(scene, 0, 10, "Theme");

    let themeItems = scene.add.container(
      isMobileDevice() ? parseStyle("xp-4") : widgetW * 0.35,
      isMobileDevice() ? parseStyle("yp-4") : themeText.y + themeText.height
    );

    const whiteIcon = createImage(scene, 0, 0, ASSET_IMG_WHITE_TILE, { scale: 0.5, scale_mb: 2 })
      .setOrigin(0, 0)
      .setInteractive();

    whiteIcon.on("pointerdown", () => {
      settingStore.theme = "white";
    });

    const darkIcon = createImage(scene, whiteIcon.getBounds().width + 20, 0, ASSET_IMG_DARK_TILE, { scale: 0.5, scale_mb: 2 })
      .setOrigin(0, 0)
      .setInteractive();

    darkIcon.on("pointerdown", () => {
      settingStore.theme = "dark";
    });

    const selectorIcon = createImage(
        scene, 
        whiteIcon.getBounds().width / 2,
        (whiteIcon.getBounds().height * 4) / 3,
        "up-icon",
        {
          scale: 0.1
        }
      )

    autorun(() => {
      if (settingStore.theme === "white") {
        selectorIcon.x = whiteIcon.getBounds().width / 2;
      } else if (settingStore.theme === "dark") {
        selectorIcon.x = (whiteIcon.getBounds().width * 3) / 2 + 20;
      }
    });

    themeItems.add([whiteIcon, darkIcon, selectorIcon]);

    themeContainer.add(themeText);
    themeContainer.add(themeItems);

    if (background) widgetContainer.add(background);

    widgetContainer.add(banner);
    widgetContainer.add(closeButton);
    widgetContainer.add(soundContainer);
    widgetContainer.add(musicContainer);
    widgetContainer.add(themeContainer);
    widgetContainer.setScale(0);

    autorun(() => {
      if (settingStore.settingsOpen) {
        backdrop.visible = true;
        scene.tweens.add({
          targets: widgetContainer,
          scaleX: 1,
          scaleY: 1,
          x: widgetContainer.x,
          y: widgetContainer.y,
          duration: 500,
          ease: "Back.Out",
        });
      } else {
        backdrop.visible = false;
        scene.tweens.add({
          targets: widgetContainer,
          scaleX: 0,
          scaleY: 0,
          duration: 500,
          ease: "Back.In",
        });
      }
    });
  }
}
