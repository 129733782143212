import { GAME_TYPE_LETTER } from "@rewire-brain/grid-engine/utils/constants";
import { GAME_ID_FIND_THE_DIFFERENT, SCENE_RESULT } from "../../../../../utils/constants";
import { calculateResults } from "../../../../GridProblems/_utils/resultsHelper";

export const gameOverCallback = (scene) => {
  const data = {
    gameType: GAME_TYPE_LETTER,
    playGrid: scene.level.grid,
    moves: scene.moves,
    timeTaken: scene.level.time_required - scene.timeLeft,
    totalTime: scene.level.time_required,
    totalCorrect: scene.totalCorrectMoves,
    score: scene.score
  }

  scene.scene.start(SCENE_RESULT, {
    data: data,
    stats: calculateResults(data),
    gameID: GAME_ID_FIND_THE_DIFFERENT
  });
}