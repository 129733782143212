import { axiosClient } from "../axiosClient";
import { courseApi } from "./api";
import { showErrorToast } from "../../components/toasts";
import { routes } from "../../routes";

export const getCourses = async () => {
  try {
    const response = await axiosClient.get(courseApi.list());
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message)
  }
}

export const getCourseDetails = async (course_id) => {
  try {
    const response = await axiosClient.get(courseApi.details(course_id));
    return response.data;
  } catch (error) {
    window.location.href = routes.getCoursesRoute();
    showErrorToast(error.response.data.message);
  }
}