import { axiosClient } from "../axiosClient";
import { showErrorToast } from "../../components/toasts";
import { activityApi } from "./api";

export const getActivities = async (course_id, exercise_id) => {
  try {
    const response = await axiosClient.get(
      activityApi.list(course_id, exercise_id)
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};

export const getActivityDetails = async (exercise_id, activity_id) => {
  try {
    const response = await axiosClient.get(
      activityApi.details(exercise_id, activity_id)
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};

export const createActivityAttempt = async (exercise_attempt_id, payload) => {
  try {
    const response = await axiosClient.post(
      activityApi.createAttempt(exercise_attempt_id),
      payload
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};

export const createResultMetaData = async (activity_attempt_id, payload) => {
  try {
    const response = await axiosClient.post(
      activityApi.createMetaData(activity_attempt_id),
      payload
    );
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};