import React from "react";
import { useLocation, Link } from "react-router-dom";

const MenuItemComponent = ({ item }) => {
  const location = useLocation();

  const getLinkClassNames = () => {
    const isActive = location.pathname === item.path;
    return `${isActive ? "text-white" : "text-grayShade3"} ml-4 md:mb-0 mb-2`;
  };

  return (
    <div className="py-2 md:py-0">
      <Link to={item.path}>
        <p className={getLinkClassNames()}>{item.title}</p>
      </Link>
    </div>
  );
};

export default MenuItemComponent;
