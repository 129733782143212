import { boardStore } from "../../_stores/BoardStore";
import { isMobileDevice, isTabletDevice, isPCDevice } from "../deviceType";

export const getFinalScale = (scaleProps) => {
  const { scale = 1, scale_mb = 1, scale_tb = 1, scale_pc = 1 } = scaleProps;
  let finalScale = boardStore.scaleFactor;

  if (isMobileDevice()) {
    finalScale *= scale_mb;
  } else if (isTabletDevice()) {
    finalScale *= scale_tb;
  } else if (isPCDevice()) {
    finalScale *= scale_pc;
  }

  finalScale *= scale;
  return finalScale
}

export const applyScale = (scale) => {
  return boardStore.scaleFactor * scale
}

export const createImage = (scene, xPos, yPos, assetKey, scaleProps) => {
  return scene.add.image(xPos, yPos, assetKey).setScale(getFinalScale(scaleProps || {}));
};

export const createText = (scene, xPos, yPos, text, styleProps, scaleProps) => {
  return scene.add.text(xPos, yPos, text, styleProps).setScale(getFinalScale(scaleProps || {}))
}