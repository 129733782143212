import React from 'react'
import { MdArrowDropDown } from "react-icons/md";
import MenuItemComponent from "./_menuItemComponent"
import { authStore } from "../../../stores/authStore";
import { menuItemsLoggedIn, menuItemsPublic } from "./_menuItemList";
import PopOver from "../../CustomUI/PopOver";

const MobileNav = () => {
  const { user, authState } = authStore

  return (
    <div>
      <PopOver
        targetElement={
          <div>
            <p data-testId="menu" className="text-grayShade3 flex items-center">
              {!authState ? "Menu" : user.candidateId} <MdArrowDropDown className="text-2xl" />
            </p>
          </div>
        }
      >
        <MobileNavMenu />
      </PopOver>
    </div>
  )
}

export default MobileNav

export const MobileNavMenu = () => {
  const { authState } = authStore;

  const handleLogout = () => { };
  return (
    <div
      role="presentation"
      className="absolute top-[70px] w-[100vw] right-0 bg-grayShade1 transition-all duration-200 ease-linear pb-2 pl-2"
    >
      {authState === true ?
        <div>
          {menuItemsLoggedIn.map((item) => (
            <MenuItemComponent item={item} index={item.title} />
          ))}

          <div onClick={handleLogout}>
            <p className="text-grayShade3 ml-4 md:mb-0 my-2 mb-4">Logout</p>
          </div>

        </div> : menuItemsPublic.map((item) => (
          <MenuItemComponent item={item} index={item.title} />
        ))}
    </div>
  )
}