import { BLACK_SHADE, DEPTH_BACK_DROP } from "../../../utils/constants";

export const getBackdrop = (scene) => {
  const backdrop = scene.add
    .rectangle(
      0,
      0,
      2 * scene.sys.game.config.width,
      2 * scene.sys.game.config.height,
      BLACK_SHADE,
      0.4
    )
    .setDepth(DEPTH_BACK_DROP)

  backdrop.setInteractive({ useHandCursor: false });

  const disableInteraction = (pointer, currentlyOver) => {
    if (currentlyOver.includes(backdrop)) {
      pointer.event.stopImmediatePropagation();
    }
  }

  scene.input.once('pointerdown', (pointer, currentlyOver) => {
    disableInteraction(pointer, currentlyOver);
  });

  scene.input.on('pointerup', (pointer, currentlyOver) => {
    disableInteraction(pointer, currentlyOver);
  });

  scene.input.on('pointermove', (pointer, currentlyOver) => {
    disableInteraction(pointer, currentlyOver);
  });

  return backdrop
}
