import { ASSET_AUDIO_CLOCK_TIMER_DOWN, ASSET_AUDIO_LEVEL_COMPLETED, ASSET_AUDIO_WORD_FAILURE, ASSET_AUDIO_WORD_SAME, ASSET_AUDIO_WORD_SUCCESS } from "../../utils/constants/assetkeys"

export const soundPlayer = (scene) => {
  const success = scene.sound.add(ASSET_AUDIO_WORD_SUCCESS)
  const failure = scene.sound.add(ASSET_AUDIO_WORD_FAILURE)
  const completed = scene.sound.add(ASSET_AUDIO_LEVEL_COMPLETED)
  const timer = scene.sound.add(ASSET_AUDIO_CLOCK_TIMER_DOWN)
  const repeat = scene.sound.add(ASSET_AUDIO_WORD_SAME)

  success.volume = 0.5
  failure.volume = 0.5
  completed.volume = 0.5
  timer.volume = 0.5
  repeat.volume = 0.5

  return {
    success,
    failure,
    completed,
    timer,
    repeat
  }
}