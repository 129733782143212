export const exerciseApi = {
  list(course_id) {
    return `/v1/api/learner/course/${course_id}/exercises`;
  },
  details(course_id, exercise_id) {
    return `/v1/api/learner/course/${course_id}/exercise/${exercise_id}`;
  },
  createAttempt(course_id) {
    return `/v1/api/learner/course/${course_id}/exercise_attempt`;
  },
};
