import React from 'react';
import FooterTop from './_FooterTop';
import FooterBottom from './_FooterBottom';
import { authStore } from "../../../stores/authStore";

const Footer = () => {
  const authState = authStore.authState

  return (
    <div>
      {!authState && <FooterTop />}
      <FooterBottom />
    </div>
  )
};

export default Footer;