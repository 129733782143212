import { MOBILE, PC, TABLET } from "../../../utils/constants";
import { boardStore } from "../../_stores/BoardStore";
import { sizeConstantsStore } from "../../_stores/SizeConstantsStore";
import { mobileBreakPoint, tabletBreakPoint } from "../../_utils/constants";

export const setGameDimension = (width, height) => {
  boardStore.gameWidth = width;

  if (boardStore.gameWidth < mobileBreakPoint) {
    sizeConstantsStore.device = MOBILE;
  } else if (boardStore.gameWidth < tabletBreakPoint) {
    sizeConstantsStore.device = TABLET;
  } else {
    sizeConstantsStore.device = PC;
  }

  boardStore.gameHeight = height;
};
