import { toJS } from "mobx"
import { userDataStore } from "../../../stores/userDataStore"
import { ACTIVITY_STATUS_COMPLETED, ACTIVITY_STATUS_NOT_COMPLETED } from "../../../utils/constants";

export const getResultPayload = (stats, data) => {
  const gameId = userDataStore.currentExercise.game_id
  const activity = toJS(userDataStore.currentActivity);
  
  const payload = {
    result: {
      status: checkStatus(stats, activity),
      metadata: {}
    }
  }

  const metadataCreators = {
    1: createMetadataForAlphabetSearch,
    4: createMetadataForWordSearch,
    5: createMetadataForSpotTheDifference,
    6: createMetadataForInsertTheNumber,
    7: createMetadataForFindTheDifferent,
    13: createMetadataForNumberSearch,
  }

  if (metadataCreators[gameId]) {
    payload.result.metadata = metadataCreators[gameId](data)
  } else {
    console.warn(`No metadata creator function found for gameId ${gameId}`)
  }

  return payload
}

export const createMetadataForAlphabetSearch = (data) => {
  const getSelectedCharactersFromMoves = (moves) => {
    return moves.map((move, index) => {
      return {
        character: move.symbol,
        position: [move.row, move.col], 
        sequence_no: index + 1,
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    selected_characters: getSelectedCharactersFromMoves(data.moves)
  }
}

export const createMetadataForNumberSearch = (data) => {
  const getSelectedNumbersFromMoves = (moves) => {
    return moves.map((move, index) => {
      return {
        number: Number(move.symbol),
        position: [move.row, move.col], 
        sequence_no: index + 1,
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    selected_numbers: getSelectedNumbersFromMoves(data.moves)
  }
}

export const createMetadataForWordSearch = (data) => {
  const getSelectedWordsFromMoves = (moves) => {
    return moves.map((move, index) => {
      return {
        word: move.word,
        start: move.start, 
        end: move.end,
        sequence_no: index + 1,
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    selected_words: getSelectedWordsFromMoves(data.moves)
  }
}

export const createMetadataForInsertTheNumber = (data) => {
  const getDraggedNumbersFromMoves = (moves) => {
    return moves.map((move) => {
      return {
        number: move.number,
        position: move.position + 1, 
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    no_of_attempts: data.moves.length,
    dragged_numbers: getDraggedNumbersFromMoves(data.moves)
  }
}

export const createMetadataForFindTheDifferent = (data) => {
  const getDraggedNumbersFromMoves = (moves) => {
    return moves.map((move, index) => {
      return {
        number: move.number,
        position: move.position + 1, 
        sequence_no: index + 1,
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    no_of_attempts: data.moves.length,
    selected_numbers: getDraggedNumbersFromMoves(data.moves)
  }
}

export const createMetadataForSpotTheDifference = (data) => {
  const getSelectedCharactersFromMoves = (moves) => {
    return moves.map((move, index) => {
      return {
        character: move.symbol,
        position: [move.row, move.col], 
        sequence_no: index + 1,
        grid_no: 2,
        move_status: parseMoveStatusString(move.status)
      }
    });
  }

  return {
    duration: parseInt(data.timeTaken),
    selected_characters: getSelectedCharactersFromMoves(data.moves)
  }
}

export const parseMoveStatusString = (moveStatus) => {
  return moveStatus.replace("-move", "");
}

export const checkStatus = (stats, activity) => {
  const userStats = stats;
  const requiredStats = activity.parameters;

  for (const key in requiredStats) {
    if (Object.prototype.hasOwnProperty.call(requiredStats, key)) {
      if (userStats[key] === undefined || userStats[key] < requiredStats[key]) {
        return ACTIVITY_STATUS_NOT_COMPLETED;
      }
    }
  }

  return ACTIVITY_STATUS_COMPLETED;
}
