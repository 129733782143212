import React from 'react'

import Container from "./components/Container"
import LinkButton from "../../components/CustomUI/LinkButton"
import { loginWithSSO } from "../../services/auth/service"

const CallToActionSection = () => {
  const handleClick = async () => {
    loginWithSSO()
  }

  return (
    <div className="bg-white">
      <Container>
        <div className="flex flex-col justify-center items-center min-h-[400px] text-center">
          <div className="max-w-[700px] md:px-0 px-10">
            <h2 className="lg:text-[48px] md:text-[42px] text-[36px] font-semibold">Start Working on Leveraging your Abilities</h2>
            <p className="text-lg text-gray-500 mt-5">Enroll in our program and improve along with us.</p>
          </div>

          <div onClick={handleClick} className="w-[200px] mt-10 h-[50px]">
            <LinkButton textSize="42px" color="orange">Enroll Now</LinkButton>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default CallToActionSection