import React, { useEffect } from 'react'
import HeroSection from "./_HeroSection"
import CallToActionSection from "./_CallToActionSection"
import WhatIsScale from "./_WhatIsScale"
import { useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { authStore } from "../../stores/authStore"

const LandingPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if(authStore.authState) {
      navigate("/courses")
    }
  }, [])

  return (
    <div>
      <Helmet>
        <title>SCALE - Leveraging Abilities</title>
        <meta name="description" content="Leadership and followership are mindsets. They’re just different ways of looking at the world. One is reactive, and the other is proactive. Where one sees a to-do list, the other sees possibilities." />
      </Helmet>
      <HeroSection />
      <WhatIsScale />
      <CallToActionSection />
    </div>
  )
}

export default LandingPage