import { action, makeAutoObservable, observable, reaction } from "mobx";
import { LIGHT_THEME } from "../../utils/constants";
import { makePersistable } from "mobx-persist-store";

class SettingStore {
  soundVolume = 0.5
  musicVolume = 0.5
  settingsOpen = false
  infoModalOpen = false
  theme = LIGHT_THEME
  muted = true

  constructor() {
    makeAutoObservable(this, {
      soundVolume: observable,
      musicVolume: observable,
      settingsOpen: observable,
      infoModalOpen: observable,
      theme: observable,
      muted: observable,
      setMusicVolume: action,
      setSoundVolume: action,
      setMute: action,
      setInfoModalOpen: action, 
      setSettingsOpen: action
    })

    reaction(
      () => [this.musicVolume, this.soundVolume],
      () => {
        if (this.musicVolume !== 0 || this.soundVolume !== 0) {
          this.setMute(false);
        }
      }
    );

    makePersistable(this, {
      name: "SettingsStore",
      properties: ["theme"],
      storage: window.localStorage,
    });
  }

  setMute(value) {
    this.muted = value
  }

  setMusicVolume(volume) {
    this.musicVolume = volume
  }

  setSoundVolume(volume) {
    this.soundVolume = volume
  }

  setSettingsOpen(state) {
    this.settingsOpen = state
  }

  setInfoModalOpen(state) {
    this.infoModalOpen = state
  }
}

export const settingStore = new SettingStore();