import { BLUE_SHADE_0x, ORANGE_SHADE_0x, WHITE_SHADE_0x } from "../../../utils/constants";
import { applyScale } from "../../_utils/styleUtilities/_scaling";

export const createWidgetBackground = (scene, width, height, xPos, yPos, fillColor = WHITE_SHADE_0x) => {
  const background = scene.add.graphics();
  background.fillStyle(fillColor, 1);
  background.fillRoundedRect(xPos, yPos, width, height, 15);
  background.lineGradientStyle(
    applyScale(20),
    BLUE_SHADE_0x,
    ORANGE_SHADE_0x,
    BLUE_SHADE_0x,
    ORANGE_SHADE_0x,
    1
  );
  background.strokeRoundedRect(xPos, yPos, width, height, 15);

  return background
}