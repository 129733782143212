import { isMobileDevice } from "../../_utils/deviceType";
import { parseStyle } from "../../_utils/styleUtilities/_position";

export const getSliderPositionProperties = (widgetW, widgetH, pad) => {
  let sliderX = null
  let sliderY = null
  let sliderWidth = null

  if(!isMobileDevice()) {
    sliderX = widgetW * 0.4
    sliderY = widgetH * 0.02
    sliderWidth = widgetW * 0.3
  } else {
    sliderX = pad / 2
    sliderY = pad / 3
    sliderWidth = widgetW * 0.8
  }

  return {
    sliderX, sliderY, sliderWidth
  }
}

export const getGridWidth = () => {
  return isMobileDevice() ? parseStyle("w-90") : parseStyle("h-70");
}