import {
  ASSET_IMG_ICON_ACCURACY,
  ASSET_IMG_ICON_BEST,
  ASSET_IMG_ICON_LONGEST,
  ASSET_IMG_ICON_SCORE,
} from "../../../utils/constants/assetkeys";

export const getParameters = (data, stats) => {
  const { accuracy, time, efficiency, score } = stats;
  const scoreData = data?.score;

  const maxScore =
    (data?.totalWords?.length ||
      data?.additionalArray?.length ||
      data?.totalCorrect ||
      data?.totalSymbols?.length) * 10;

  let parameters = [
    {
      name: "Accuracy",
      progress: accuracy,
      maxValue: 100,
      unit: "%",
      icon: ASSET_IMG_ICON_ACCURACY,
    },
    {
      name: "Score",
      progress: scoreData ? scoreData : score,
      maxValue: scoreData ? maxScore : 100,
      unit: scoreData ? `/${maxScore}` : "%",
      icon: ASSET_IMG_ICON_SCORE,
    },
    {
      name: "Time Used",
      progress: time,
      maxValue: 100,
      unit: "%",
      icon: ASSET_IMG_ICON_LONGEST,
    },
    {
      name: "Efficiency",
      progress: efficiency,
      maxValue: 100,
      unit: "%",
      icon: ASSET_IMG_ICON_BEST,
    },
  ];

  return parameters;
};
