import {
  BLACK_SHADE,
  TEXT_SIZE_MEDIUM,
  WHITE_SHADE,
} from "../../../../../utils/constants";
import { getDimensions } from "./_getDimensions";
import { getTile } from "../../../../_components/getTile";
import { isMobileDevice } from "../../../../_utils/deviceType";
import { changeFontColorWithTheme } from "../../../../_utils/themeChange";
import { applyScale } from "../../../../_utils/styleUtilities/_scaling";

export const renderPrimaryNumbers = (scene, isAscending) => {
  const sortedNumbers = scene.level.configurations.numbers.sort((a, b) =>
    isAscending ? a - b : b - a
  );
  const { tileSize, primaryArrayX, primaryArrayY } = getDimensions(
    scene,
    sortedNumbers.length,
    false
  );

  scene.primaryArrayLabel = scene.add
    .text(
      isMobileDevice() ? primaryArrayX : scene.scale.width / 2,
      primaryArrayY - tileSize / 2 - TEXT_SIZE_MEDIUM,
      "Sequence",
      {
        fontSize: TEXT_SIZE_MEDIUM,
        color: WHITE_SHADE,
        fontFamily: "Arial",
      }
    )
    .setOrigin(0.5);

  sortedNumbers.forEach((number, index) => {
    const startX = isMobileDevice()
      ? primaryArrayX
      : primaryArrayX + index * (tileSize + scene.tileGap);
    const startY = isMobileDevice()
      ? primaryArrayY + index * (tileSize + scene.tileGap)
      : primaryArrayY;

    const bg = getTile(scene, startX, startY, tileSize);

    const numberText = scene.add
      .text(startX, startY, number, fontStyle())
      .setOrigin(0.5);

    changeFontColorWithTheme(numberText);
    scene.numberObjects.push({ bg, numberText, x: startX, y: startY });
  });
};

export const fontStyle = () => {
  const style = {
    fontSize: isMobileDevice()
      ? applyScale(50)
      : applyScale(100),
    color: BLACK_SHADE,
    fontFamily: "Arial",
    fontStyle: "bold",
  };

  return style;
};
