export const getCommonNumber = (grid) => {
  const counts = {};

  for (let row of grid) {
    for (let num of row) {
      const number = Number(num)
      counts[number] = (counts[number] || 0) + 1;
    }
  }

  let mostCommonNumber;
  let maxCount = -1;

  for (let num in counts) {
    if (counts[num] > maxCount) {
      mostCommonNumber = parseInt(num);
      maxCount = counts[num];
    }
  }

  return mostCommonNumber;
};

export const getCorrectMovesCount = (grid, commonNumber) => {
  const totalSymbols = grid.length * grid[0].length;
  const count = grid.flat().filter((num) => Number(num) === Number(commonNumber)).length;
  return totalSymbols - count;
};

export const convertTo2D = (array) =>
  Array.isArray(array[0]) ? array : [array];
