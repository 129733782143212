import Phaser from "phaser";
import { boardStore } from "../_stores/BoardStore";
import { BackButton } from "../GridProblems/_components/buttons/BackButton";
import { LevelComponent } from "../_components/LevelComponent";
import { getColumns } from "../GridProblems/_utils/levelHelper";
import {
  SCENE_LEVEL_DESCRIPTION,
  SCENE_MAIN_MENU,
} from "../../utils/constants";
import { ASSET_IMG_LEVELS_BANNER } from "../../utils/constants/assetkeys";
import { Background } from "../GridProblems/_components/Background";
import { createWidgetBackground } from "../GridProblems/_components/WidgetBackground";
import { isPCDevice } from "../_utils/deviceType";
import { renderControlButtons } from "../_components/_renderBaseScene";
import { parseStyle } from "../_utils/styleUtilities/_position";
import { createImage } from "../_utils/styleUtilities/_scaling";
import { getActivities } from "../../services/activities/service";
import { userDataStore } from "../../stores/userDataStore";

export default class LevelDescription extends Phaser.Scene {
  constructor() {
    super({ key: SCENE_LEVEL_DESCRIPTION });
    this.activities = []
  }

  init() {
    this.activities = [];
    new Background(this);
  }

  create() {
    this.game.events.on("refetch-activities", () => {
      this.loadActivities();
    })
    this.loadActivities();
  }

  loadActivities() {
    getActivities(userDataStore.currentCourse.id, userDataStore.currentExercise.id).then((res) => {
      this.activities = res;
      this.renderActivities();
    });
  }

  calculateAvailableSpace() {
    return Math.floor(
      (Math.min(parseStyle("w-90"), parseStyle("h-90")) *
        0.9) /
      boardStore.rows
    );
  }

  renderActivities() {
    renderControlButtons(this, {
      isInfoButton: false
    });

    let startX = isPCDevice() ? parseStyle("w-15") : parseStyle("w-5");
    let startY = isPCDevice() ? parseStyle("h-10") : parseStyle("h-15");

    const width = parseStyle("w-100") - 2 * startX;
    const height = parseStyle("h-100") - 2 * startY;

    new BackButton(this, startX, startY, () => {
      this.scene.start(SCENE_MAIN_MENU);
    }).setDepth(1);

    const wrapper = this.add.container(startX, startY);

    const background = createWidgetBackground(this, width, height, 0, 0);

    const levelsBanner =
      createImage(this, width / 2, 0, ASSET_IMG_LEVELS_BANNER, {
        scale_mb: 1.3,
        scale_tb: 1.3,
        scale_pc: 1
      });

    const levelContainerPadding = parseStyle("xp-6");
    const levelContainerWidth = width - 2 * levelContainerPadding;

    let cols = getColumns();
    let cellWidth = levelContainerWidth / cols;

    this.activities.forEach((level, index) => {
      const posX =
        (index % cols) * cellWidth + levelContainerPadding + startX;
      const posY =
        Math.floor(index / cols) * cellWidth +
        levelsBanner.getBounds().height +
        startY;

        const itemHeight = cellWidth - parseStyle("yp-1");
        const itemWidth = cellWidth - parseStyle("xp-1");

      new LevelComponent(this, posX, posY, itemWidth, itemHeight, level);
    });

    wrapper.add(background);
    wrapper.add(levelsBanner);
  }
}
