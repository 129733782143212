import { autorun } from "mobx";

import { settingStore } from "../_stores/SettingsStore";

import {
  BLACK_SHADE,
  LIGHT_THEME,
  WHITE_SHADE,
  DARK_THEME
} from "../../utils/constants";
import { ASSET_IMG_DARK_TILE, ASSET_IMG_WHITE_TILE } from "../../utils/constants/assetkeys";

export const changeFontColorWithTheme = (numberObject) => {
  autorun(() => {
    numberObject.setColor(
      settingStore.theme === LIGHT_THEME ? BLACK_SHADE : WHITE_SHADE
    );
  });
}

// This returned object is used by the gridEngine instance to emit a
// theme change event, that will be listened in Grid Engine Package
// and it will change the theme
export const themeChangeEventObject = () => {
  return {
    tile_key: settingStore.theme === LIGHT_THEME
    ? ASSET_IMG_WHITE_TILE
    : ASSET_IMG_DARK_TILE,
    isDarkTheme: settingStore.theme === DARK_THEME
  }
}