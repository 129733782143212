import React from 'react'

const Container = ({ children }) => {
  return (
    <div className="flex justify-center h-[100%]">
      <div className="max-w-[1200px] px-5 w-[100%]">
        {children}
      </div>
    </div>
  )
}

export default Container