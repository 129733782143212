import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../routes";
import ProgressBar from "../components/CustomUI/ProgressBar";
import LinkButton from "../components/CustomUI/LinkButton";

import {
  COURSE_STATUS_STARTED,
  COURSE_STATUS_NOT_STARTED,
  COURSE_STATUS_COMPLETED,
} from "../utils/constants";

import { formatTime } from "../utils/formatTime";
import { getCourseStatusFromProgress } from "../utils/getCourseStatusFromProgress";

const CourseCard = ({ course }) => {
  const [courseStatus, setCourseStatus] = useState(COURSE_STATUS_NOT_STARTED)

  useEffect(() => {
    setCourseStatus(getCourseStatusFromProgress(course.progress))
  }, [course])

  const renderCourseCardFooter = (courseStatus) => {
    switch (courseStatus) {
      case COURSE_STATUS_NOT_STARTED:
        return (
          <div className="flex items-center justify-between">
            <div className="p-3 text-sm">
              <p>Total Exercises: {course.total_exercises}</p>
              <p>Estimated Time: {formatTime(course.estimated_time)} min</p>
            </div>

            <div className="mr-3">
              <LinkButton
                testId={"course-links"}
                path={routes.getCourseDetailsRoute(course.id)}
                color="orange"
              >
                START
              </LinkButton>
            </div>
          </div>
        );
      case COURSE_STATUS_STARTED:
      case COURSE_STATUS_COMPLETED:
        return (
          <div className="p-3">
            <ProgressBar progress={course?.progress} />
            <div className="mt-3 flex justify-between items-center">
              <span className="text-sm">
                {parseInt(course?.progress)}% Complete
              </span>
              <div>
                <LinkButton color="orange" path={routes.getCourseDetailsRoute(course.id)}>
                  {course.status === COURSE_STATUS_STARTED ? "RESUME" : "RETAKE"}
                </LinkButton>
              </div>
            </div>
          </div>
        );
      default:
        return <></>
    }
  };

  return (
    <div
      data-testid="course-card"
      className="bg-white shadow-lg rounded-md overflow-hidden"
    >
      <Link
        data-testid="course-links"
        to={routes.getCourseDetailsRoute(course.id)}
      >
        <div className="h-[150px] flex justify-center items-center border-b-[1px]">
          <p className="text-2xl font-semibold">{course.category}</p>
        </div>
      </Link>

      {renderCourseCardFooter(courseStatus)}
    </div>
  );
};

export default CourseCard;
