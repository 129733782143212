import axios from "axios";
import { authStore } from "../stores/authStore";
import { showErrorToast } from "../components/toasts";
import { refreshToken } from "./auth/service";
import { authApi } from "./auth/api";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 3000,
  withCredentials: true,
});

const axiosClientWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 3000,
});

const logoutUser = () => {
  authStore.setAuth(false);
  window.location.href = "/";
  showErrorToast("Session Expired. Please login again!");
}

axiosClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;
    
    if (response && response.status === 401) {
      if (config.url === authApi.refreshToken()) {
        logoutUser();
        return Promise.reject(error);
      }

      if (!config._retry) {
        config._retry = true;

        try {
          await refreshToken();
          return axiosClient(config);
        } catch (refreshError) {
          logoutUser();
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export {
  axiosClient,
  axiosClientWithoutAuth
}