import React from 'react'
import HeadingLarge from "../../components/CustomUI/HeadingLarge"

const WhatIsScale = () => {
  return (
    <div className="relative pt-20 flex justify-center">
      <div className="bg-white absolute top-0 left-0 w-[100%] h-[60%]" />
      <div className="max-w-[1500px] w-[100%] px-5">
        <div className="relative grid md:grid-cols-2 md:gap-5 lg:gap-10">
          <div className="bg-white text-lg rounded-md p-10 mb-10 md:mb-0 shadow-lg border-[1px]">
            <div className="text-blue-shade">
              <HeadingLarge title="Why Scale?" />
            </div>

            <p className="mt-5">Leadership and followership are mindsets. They’re just different ways of looking at the world. One is reactive, and the other is proactive. Where one sees a to-do list, the other sees possibilities.</p>

            <p className="my-4">If your actions inspire others to dream more, learn more, do more and become more, you become a leader.</p>

            <p className="">So, don’t wait for the title. Leadership isn’t something that anyone can give you—you have to earn it and claim it for yourself.</p>
          </div>
          <div className="bg-white text-lg rounded-md p-10 shadow-lg border-[1px]">
            <div className="text-blue-shade">
              <HeadingLarge title="How it works?" />
            </div>

            <ul className="list-disc ml-5 mt-5">
              <li className="mb-3">With no Maths, no Physics, no Personality based questions the test asks something which can be answered by any literate, be it a school dropout.</li>
              <li className="mb-3">Self diagnosis by taking the online take from home SCALE test helps in baselining the strengths and roadblocks.</li>
              <li className="mb-3">Specially curated test report helps identification of traits which require attention.</li>
              <li className="mb-3">A step by step guide map for improvement of lagging traits helps in mastering your vision.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatIsScale