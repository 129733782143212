import {
  ASSET_IMG_BACKGROUND,
  ASSET_IMG_LEVELS_BANNER,
  ASSET_IMG_RESULTS_BANNER,
  ASSET_IMG_ORANGE_BANNER,
  ASSET_IMG_REPLAY_BUTTON,
  ASSET_IMG_WORDS_FOUND,
  ASSET_IMG_NUMBERS_FOUND,
  ASSET_IMG_LETTERS_FOUND,
  ASSET_IMG_WHITE_TILE,
  ASSET_IMG_DARK_TILE,
  ASSET_IMG_GAME_PLAY,
  ASSET_IMG_SCORE_BADGE,
  ASSET_IMG_TIMER_BADGE,
  ASSET_IMG_BLUE_SLAB,
  ASSET_IMG_ORANGE_SLAB,
  ASSET_IMG_SLAB,
  ASSET_AUDIO_BGM,
  ASSET_AUDIO_TRAVERSE,
  ASSET_AUDIO_WORD_SUCCESS,
  ASSET_AUDIO_WORD_FAILURE,
  ASSET_AUDIO_WORD_SAME,
  ASSET_AUDIO_LEVEL_COMPLETED,
  ASSET_AUDIO_CLOCK_TIMER_DOWN,
  ASSET_IMG_ICON_LOCK,
  ASSET_IMG_ICON_TIMER,
  ASSET_IMG_ICON_DONE,
  ASSET_IMG_ICON_RELOAD,
  ASSET_IMG_ICON_BACK,
  ASSET_IMG_ICON_CLOSE,
  ASSET_IMG_ICON_EXIT,
  ASSET_IMG_ICON_INFO,
  ASSET_IMG_ICON_SETTINGS,
  ASSET_IMG_ICON_ACCURACY,
  ASSET_IMG_ICON_BEST,
  ASSET_IMG_ICON_LONGEST,
  ASSET_IMG_ICON_SCORE,
  ASSET_IMG_ICON_UP,
  ASSET_IMG_ICON_REPLAY,
  ASSET_IMG_ICON_STATS,
  ASSET_IMG_ICON_CANCEL,
  ASSET_IMG_ICON_SOUND_ON,
  ASSET_IMG_ICON_SOUND_OFF,
  ASSET_IMG_BUTTON_CONTINUE,
  ASSET_IMG_PLAY_BUTTON,
  ASSET_IMG_LEVEL_COMPONENT
} from '../../utils/constants/assetkeys';

export const sharedAssets = {
  images: [
    { key: ASSET_IMG_BACKGROUND, file: "_images/background.png" },
    { key: ASSET_IMG_REPLAY_BUTTON, file: "_images/play_next.png" },
    { key: ASSET_IMG_ORANGE_BANNER, file: "_images/settings_banner.png" },
    { key: ASSET_IMG_RESULTS_BANNER, file: "_images/results_banner.png" },
    { key: ASSET_IMG_LEVELS_BANNER, file: "_images/levels_banner.png" },
    { key: ASSET_IMG_WORDS_FOUND, file: "_images/words_found.png" },
    { key: ASSET_IMG_NUMBERS_FOUND, file: "_images/numbers_found.png" },
    { key: ASSET_IMG_LETTERS_FOUND, file: "_images/letters_found.png" },
    { key: ASSET_IMG_WHITE_TILE, file: "_images/tiles/white_tile.png" },
    { key: ASSET_IMG_DARK_TILE, file: "_images/tiles/dark_tile.png" },
    { key: ASSET_IMG_GAME_PLAY, file: "_images/game_play.png" },
    { key: ASSET_IMG_SCORE_BADGE, file: "_images/score_badge.png" },
    { key: ASSET_IMG_TIMER_BADGE, file: "_images/timer_badge.png" },
    { key: ASSET_IMG_BLUE_SLAB, file: "_images/slab_blue.png" },
    { key: ASSET_IMG_ORANGE_SLAB, file: "_images/orange_slab.png" },
    { key: ASSET_IMG_SLAB, file: "_images/slab.png" },
    { key: ASSET_IMG_BUTTON_CONTINUE, file: "_images/continue_button.png" },
    { key: ASSET_IMG_PLAY_BUTTON, file: "_images/play_button.png" },
    { key: ASSET_IMG_LEVEL_COMPONENT, file: "_images/level_component.png" }
  ],
  audios: [
    { key: ASSET_AUDIO_TRAVERSE, file: "_audios/traverse.wav" },
    { key: ASSET_AUDIO_WORD_SUCCESS, file: "_audios/success.wav" },
    { key: ASSET_AUDIO_WORD_FAILURE, file: "_audios/failure.wav" },
    { key: ASSET_AUDIO_WORD_SAME, file: "_audios/same.wav" },
    { key: ASSET_AUDIO_LEVEL_COMPLETED, file: "_audios/completed.wav" },
    { key: ASSET_AUDIO_CLOCK_TIMER_DOWN, file: "_audios/clock-timer.mp3" },
    { key: ASSET_AUDIO_BGM, file: "_audios/bgm.mp3" },
  ],
  icons: [
    { key: ASSET_IMG_ICON_SETTINGS, file: "_icons/settings.png" },
    { key: ASSET_IMG_ICON_CLOSE, file: "_icons/close.png" },
    { key: ASSET_IMG_ICON_RELOAD, file: "_icons/reload.png" },
    { key: ASSET_IMG_ICON_ACCURACY, file: "_icons/accuracy.png" },
    { key: ASSET_IMG_ICON_BEST, file: "_icons/achievement.png" },
    { key: ASSET_IMG_ICON_LONGEST, file: "_icons/best.png" },
    { key: ASSET_IMG_ICON_SCORE, file: "_icons/prize.png" },
    { key: ASSET_IMG_ICON_UP, file: "_icons/up.png" },
    { key: ASSET_IMG_ICON_BACK, file: "_icons/back.png" },
    { key: ASSET_IMG_ICON_LOCK, file: "_icons/locked.png" },
    { key: ASSET_IMG_ICON_INFO, file: "_icons/info.png" },
    { key: ASSET_IMG_ICON_TIMER, file: "_icons/timer.png" },
    { key: ASSET_IMG_ICON_CANCEL, file: "_icons/wrong.png" },
    { key: ASSET_IMG_ICON_STATS, file: "_icons/stats.png" },
    { key: ASSET_IMG_ICON_REPLAY, file: "_icons/replay.png" },
    { key: ASSET_IMG_ICON_DONE, file: "_icons/done.png" },
    { key: ASSET_IMG_ICON_EXIT, file: "_icons/exit.png" },
    { key: ASSET_IMG_ICON_SOUND_ON, file: "_icons/sound_on.png" },
    { key: ASSET_IMG_ICON_SOUND_OFF, file: "_icons/sound_off.png" },
  ],
  text: [],
};

export const loadCommonAssets = (scene, path) => {
  scene.load.setPath(path);
  sharedAssets.audios.forEach((audio) => {
    scene.load.audio(audio.key, audio.file);
  });

  sharedAssets.images.forEach((image) => {
    scene.load.image(image.key, image.file);
  });

  sharedAssets.icons.forEach((icon) => {
    scene.load.image(icon.key, icon.file);
  });
};
