import Phaser from "phaser";
import ShakePositionPlugin from "phaser3-rex-plugins/plugins/shakeposition-plugin";
import ShinePostFX from "../../Shaders/ShinePostFx";
import WipePostFX from "../../Shaders/WipePostFx";
import { DARK_GRAY_SHADE } from "../../utils/constants";
import CheckWebGL from "../_scenes/WebGLCheckScene";

export const getConfig = (scenes) => {
  const config = {
    type: Phaser.AUTO,
    parent: "phaser-container",
    dom: {
      createContainer: true,
    },
    backgroundColor: DARK_GRAY_SHADE,
    plugins: {
      global: [
        {
          key: "rexShakePosition",
          plugin: ShakePositionPlugin,
          start: true,
        }
      ],
    },
    scale: {
      mode: Phaser.Scale.EXPAND,
      width: window.innerWidth * window.devicePixelRatio,
      height: window.innerHeight * window.devicePixelRatio,
    },
    physics: {
      default: "arcade",
      arcade: {
        gravity: { y: 0 },
        debug: true,
      },
    },
    resolution: 1,
    scene: [CheckWebGL, ...scenes],
    pipeline: { ShinePostFX, WipePostFX },
    debug: true,
    resolution: 1
  };

  return config;
};
