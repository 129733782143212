import Bootstrap from "./scenes/Bootstrap";
import Game from "./scenes/Game";
import GameResult from "../../_scenes/GameResult";
import MainMenu from "../../_scenes/MainMenu";
import LevelDescription from "../../_scenes/LevelDescription";

import { getConfig } from "../../_utils/getConfig";

const config = getConfig([Bootstrap, MainMenu, LevelDescription, Game, GameResult])

export default config