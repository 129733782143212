import { axiosClient } from "../axiosClient";
import { routes } from "../../routes";
import { showErrorToast, showSuccessToast } from "../../components/toasts";
import { authStore } from "../../stores/authStore";
import { authApi } from "./api";

export const loginWithSSO = () => {
  window.location.href = routes.getSSOUrl();
};

export const refreshToken = async () => {
  try {
    const response = await axiosClient.get(authApi.refreshToken());
    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};

export const logout = async (callback) => {
  try {
    const response = await axiosClient.get(authApi.logout());

    if (response.data.code === 200) {
      authStore.setAuth(false);
      showSuccessToast("Logged out Successfully");
      if (typeof callback === "function") {
        callback();
      }
    } else {
      showErrorToast("Failed to log out. Please try again.");
    }

    return response.data;
  } catch (error) {
    showErrorToast(error.response.data.message);
  }
};
