import React, { useEffect, useRef, useState } from "react";

const PopOver = ({ children, targetElement }) => {
  const [open, setOpen] = useState(false);

  const targetElementRef = useRef();
  const popOverRef = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        open &&
        !targetElementRef.current?.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open]);

  return (
    <div>
      <div onClick={() => setOpen(p => !p)} ref={targetElementRef}>{targetElement}</div>

      {open ? <div ref={popOverRef}>{children}</div> : <></>}
    </div>
  );
};

export default PopOver;
