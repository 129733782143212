export const routes = {
  getHomeRoute() {
    return "/";
  },

  getCoursesRoute() {
    return "/courses"
  },

  getCourseDetailsRoute(id = ":id") {
    return `/course/${id}`;
  },

  getWordSearchRoute() {
    return "/word-search";
  },

  getNumberSearchRoute() {
    return "/number-search";
  },

  getAlphabetSearchRoute() {
    return "/alphabet-search";
  },

  getSpotTheDifferenceRoute() {
    return "/spot-the-difference";
  },

  getArrangeTheNumbersRoute() {
    return "/arrange-the-numbers";
  },

  getFindTheDifferentRoute() {
    return "/find-the-different";
  },
  
  getAuthSuccessRoute() {
    return `/auth/success`
  },

  getAuthFailureRoute() {
    return `/auth/failure`
  },

  getSSOUrl() {
    return `${process.env.REACT_APP_API_BASE_URL}/auth/elitmus`
  }
};
