export const ASSET_IMG_BACKGROUND = "background"
export const ASSET_IMG_LOGO = "logo"
export const ASSET_IMG_LEVELS_BANNER = "levels-banner"
export const ASSET_IMG_RESULTS_BANNER = "results-banner"
export const ASSET_IMG_ORANGE_BANNER = "orange-banner"
export const ASSET_IMG_REPLAY_BUTTON = "replay-button"
export const ASSET_IMG_WORDS_FOUND = "words-found"
export const ASSET_IMG_NUMBERS_FOUND = "numbers-found"
export const ASSET_IMG_LETTERS_FOUND = "letters-found"
export const ASSET_IMG_WHITE_TILE = "white-tile"
export const ASSET_IMG_DARK_TILE = "dark-tile"
export const ASSET_IMG_GAME_PLAY = "game-play"
export const ASSET_IMG_SCORE_BADGE = "score-badge"
export const ASSET_IMG_TIMER_BADGE = "timer-badge"
export const ASSET_IMG_BLUE_SLAB = "blue-slab"
export const ASSET_IMG_ORANGE_SLAB = "orange-slab"
export const ASSET_IMG_SLAB = "slab"
export const ASSET_IMG_BUTTON_CONTINUE = "continue-button"
export const ASSET_IMG_PLAY_BUTTON = "play-button"
export const ASSET_IMG_LEVEL_COMPONENT = "level-component"

export const ASSET_AUDIO_BGM = "main-menu-bgm"
export const ASSET_AUDIO_TRAVERSE = "traverse"
export const ASSET_AUDIO_WORD_SUCCESS = "word-success"
export const ASSET_AUDIO_WORD_FAILURE = "word-failure"
export const ASSET_AUDIO_WORD_SAME = "word-same"
export const ASSET_AUDIO_LEVEL_COMPLETED = "level-completed"
export const ASSET_AUDIO_CLOCK_TIMER_DOWN = "clock-timer-down"

export const ASSET_IMG_ICON_LOCK = "lock-icon"
export const ASSET_IMG_ICON_TIMER = "timer-icon"
export const ASSET_IMG_ICON_DONE = "done-icon"
export const ASSET_IMG_ICON_RELOAD = "reload-icon"
export const ASSET_IMG_ICON_BACK = "back-icon"
export const ASSET_IMG_ICON_CLOSE = "close-icon"
export const ASSET_IMG_ICON_EXIT = "exit-icon"
export const ASSET_IMG_ICON_INFO = "info-icon"
export const ASSET_IMG_ICON_SETTINGS = "settings-icon"
export const ASSET_IMG_ICON_ACCURACY = "accuracy-icon"
export const ASSET_IMG_ICON_BEST = "best-icon"
export const ASSET_IMG_ICON_LONGEST = "longest-icon"
export const ASSET_IMG_ICON_SCORE = "score-icon"
export const ASSET_IMG_ICON_UP = "up-icon"
export const ASSET_IMG_ICON_CANCEL = "cancel-icon"
export const ASSET_IMG_ICON_STATS = "stats-icon"
export const ASSET_IMG_ICON_REPLAY = "replay-icon"
export const ASSET_IMG_ICON_SOUND_ON = "sound-on"
export const ASSET_IMG_ICON_SOUND_OFF = "sound-off"