import Phaser from "phaser";

import {
  GridEngine,
  ScoreManager,
  LabelManager,
  TimerManager,
  ControlsManager,
  EventsManager,
} from "@rewire-brain/grid-engine";

import { settingStore } from "../../../_stores/SettingsStore";
import { autorun } from "mobx";

import {
  DARK_THEME,
  EVENT_THEME_CHANGE,
  LIGHT_THEME,
  SCENE_GAME,
  SCENE_RESULT,
} from "../../../../utils/constants";
import {
  EVENT_GAME_OVER,
  EVENT_GAME_RUNNING,
  GAME_ID_SPOT_THE_DIFFERENCE,
  GAME_TYPE_DIFF,
  GAME_TYPE_LETTER,
} from "@rewire-brain/grid-engine/utils/constants";
import { Background } from "../../_components/Background";
import { calculateResults } from "../../_utils/resultsHelper";
import { renderControlButtons } from "../../../_components/_renderBaseScene";
import { ASSET_IMG_DARK_TILE, ASSET_IMG_WHITE_TILE } from "../../../../utils/constants/assetkeys";
import { themeChangeEventObject } from "../../../_utils/themeChange";
import { isMobileDevice, isTabletDevice } from "../../../_utils/deviceType";
import { getControlButtonPositions } from "../../../_utils/getButtonPositions";
import { applyScale } from "../../../_utils/styleUtilities/_scaling";
import { getActivityDetails } from "../../../../services/activities/service";
import { userDataStore } from "../../../../stores/userDataStore";

export default class Game extends Phaser.Scene {
  constructor() {
    super({ key: SCENE_GAME });
  }

  init() {
    new Background(this)
    this.level = undefined;
  }

  getPositions(width, height) {
    let refGridX = width * 0.3;
    let refGridY = height * 0.5;

    let playGridX = width * 0.7;
    let playGridY = refGridY;

    let gridWidth = width * 0.35;

    let scoreBadgeX = refGridX - gridWidth / 2
    let scoreBadgeY = refGridY - gridWidth / 2 - applyScale(80)

    // If we pass nothing or undefined to the timerBadge, 
    // It will take the default position according to the grid
    let timerBadgeX = undefined
    let timerBadgeY = undefined

    if (isMobileDevice() || isTabletDevice()) {
      refGridX = width * 0.5;
      refGridY = height * 0.35;

      playGridX = refGridX;
      playGridY = height * 0.7;

      gridWidth = width * 0.5;

      const { settingsButtonX, settingsButtonY } = getControlButtonPositions()

      scoreBadgeX = settingsButtonX
      scoreBadgeY = settingsButtonY + 150

      timerBadgeX = width - settingsButtonX
      timerBadgeY = settingsButtonY + 150
    }

    return {
      refGridX,
      refGridY,
      playGridX,
      playGridY,
      gridWidth,
      scoreBadgeX,
      scoreBadgeY,
      timerBadgeX,
      timerBadgeY
    };
  }

  loadGameConfig() {
    getActivityDetails(userDataStore.currentExercise.id, userDataStore.currentActivityId).then((res) => {
      this.level = res;
      userDataStore.setCurrentActivity(this.level)
      this.renderGame()
    })
  }

  renderGame() {
    renderControlButtons(this);

    const width = this.scale.width;
    const height = this.scale.height;

    const { refGridX, refGridY, playGridX, playGridY, gridWidth, scoreBadgeX, scoreBadgeY, timerBadgeX, timerBadgeY } =
      this.getPositions(width, height);

    const dictionary = this.cache.text.get("dictionary");

    this.referenceGridEngine = new GridEngine({
      scene: this,
      midX: refGridX,
      midY: refGridY,
      width: gridWidth,
      height: gridWidth,
      gameType: GAME_TYPE_LETTER,
      isDarkTheme: settingStore.theme === LIGHT_THEME ? false : true,
      dictionary: dictionary,
    });

    autorun(() => {
      this.referenceGridEngine.themeChangeEvent.emit(
        "theme-change",
        {
          tile_key: settingStore.theme === LIGHT_THEME
          ? ASSET_IMG_WHITE_TILE
          : ASSET_IMG_DARK_TILE,
          isDarkTheme: settingStore.theme === DARK_THEME
        }
      );
    });

    this.referenceGridEngine.setGridDimension(
      this.level.configurations.row,
      this.level.configurations.column,
      this.level.configurations.grid_1
    );
    this.referenceGridEngine.setPreviewMode(true);

    this.gridEngine = new GridEngine({
      scene: this,
      midX: playGridX,
      midY: playGridY,
      width: gridWidth,
      height: gridWidth,
      gameType: GAME_TYPE_DIFF,
      isDarkTheme: settingStore.theme === LIGHT_THEME ? false : true,
      dictionary: dictionary,
    });

    autorun(() => {
      this.gridEngine.themeChangeEvent.emit(
        EVENT_THEME_CHANGE,
        themeChangeEventObject
      );
    });

    this.gridEngine.setGridDimension(
      this.level.configurations.row,
      this.level.configurations.column,
      this.level.configurations.grid_2
    );

    this.gridEngine.addBottomSlab("Spot the difference");
    this.gridEngine.setReferenceGrid(this.level.configurations.grid_1);

    this.gridEngine.setSoundVolume(settingStore.soundVolume);
    autorun(() => {
      this.gridEngine.setSoundVolume(settingStore.soundVolume);
    });

    this.eventManager = new EventsManager(this.gridEngine);

    this.scoreManager = new ScoreManager(
      this.gridEngine,
      scoreBadgeX,
      scoreBadgeY
    );

    this.timerManager = new TimerManager(
      this.gridEngine,
      timerBadgeX,
      timerBadgeY
    );

    this.timerManager.setTimer(this.level.time_required);

    this.labelManager = new LabelManager(this.gridEngine);

    this.controlsManager = new ControlsManager(
      this.gridEngine,
      this.scoreManager,
      this.labelManager,
      this.timerManager
    );

    this.controlsManager.setMovements({
      disableMovements: settingStore.settingsOpen ? true : false,
      allowDrag: false,
    });

    autorun(() => {
      this.controlsManager.setMovements({
        disableMovements: settingStore.settingsOpen ? true : false,
        allowDrag: false,
      });
    });

    this.controlsManager.setSceneAfterGameOverCallback(() => {});

    this.gridEngine.gameStateEvent.on(EVENT_GAME_OVER, (gameStats) => {
      const data = {
        gameType: gameStats.gameType,
        grid: gameStats.grid,
        targetSymbol: gameStats.targetSymbol,
        correctSymbols: gameStats.correctSymbols,
        moves: gameStats.moves,
        totalSymbols: gameStats.totalSymbols,
        timeTaken: gameStats.timeTaken,
        totalTime: gameStats.totalTime,
      }

      this.scene.start(SCENE_RESULT, {
        stats: calculateResults(data),
        data: data,
        gameID: GAME_ID_SPOT_THE_DIFFERENCE
      });
    });
    
    this.gridEngine.gameStateEvent.on(EVENT_GAME_RUNNING, (gameStats) => {})
  }

  create() {
    this.loadGameConfig()
  }
}
